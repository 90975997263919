import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { commonLabels } from '../../utils/CommonLabels';
import { ISurveyFormsQuestion, SurveyOptions } from '../../types/SurveyProps';
import {
	MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { useAppSelector } from '../../app/Hooks';
import {
	selectSurveyFormsQuestion,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { RootState } from '../../app/Store';
import { getCheckBoxState } from '../../redux/QuestionnaireSurveySlice';
import StyledTooltip from '../StyledTooltip';
import { isDuplicateQuestion } from '../../utils/CommonMethods';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import QuestionInstruction from './QuestionInstruction';
import QuestionActions from './QuestionActions';
import ConfigureOptions from './ConfigureOptions';
import InputCharacterRemaining from './InputCharacterRemaining';

const useStyles = makeStyles({
	submitHide: {
		display: 'none',
	},
	titleInput: {
		flex: '1 0 33%',
	},
	errorMsg: {
		flexBasis: '100%',
		margin: '0',
		paddingLeft: '15px',
	},
	errorOptionMsg: {
		margin: '0',
	},
	formControlOuter: {
		marginTop: '0',
		width: '100%',
		display: 'flex',
		'&.MuiFormControl-root': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	formControlInputCustom: {
		'&.MuiFormControl-root': {
			width: '100%',
		},
	},
	formControlInputBox: {
		width: '100%',
	},
	formControlInputIns: {
		'&.MuiFormControl-root': {
			width: '100%',
			marginLeft: '15px',
		},
	},
	formControlSelectCustom: {
		width: '40%',
	},
	formControlMultiOuter: {
		marginTop: '0',
		width: '100%',
		display: 'flex',
		'&.MuiFormControl-root': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
});
const QuestionBox = styled(Box)({
	display: 'flex',
	flex: '2 1 auto',
	padding: '10px 10px 10px 20px',
});
const QuestionTitle = styled(Box)({
	display: 'flex',
	marginTop: '0px',
	alignItems: 'end',
	height: 'auto',
	flexWrap: 'wrap',
	fontWeight: '400',
	fontSize: '16px',
	lineHeight: '24px',
	fontFamily: '"JohnsonText-Regular"',
});
const RemoveOptionBox = styled(Box)({
	padding: '0 2% 0 8%',
	height: '11px',
	cursor: 'pointer',
});
const QuestionType = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '14px',
	lineHeight: '12px',
	fontWeight: '500',
	display: 'flex',
	justifyContent: 'space-between',
	paddingLeft: '15px',
});
const QuestionOptions = styled(Box)({
	paddingLeft: '15px',
});
const QuestionNumber = styled(Box)({
	paddingBottom: '5px',
	paddingRight: '5px',
	flex: '0 1 0',
});

const QuestionOpenEnded: React.FC<{
	item: ISurveyFormsQuestion;
	index: number;
	onDelete: (i: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion, index: number) => void;
}> = ({ item, index, onDelete, onQueClone, onQueSave }) => {
	const openOptions: SurveyOptions[] = [{ options: '', condition: '' }];
	const classes = useStyles();
	const [isQuestionEmpty, setIsQuestionEmpty] = useState(false);
	const [isPopUpOpen, setIsPopupOpen] = useState(false);
	const questionFormRef = useRef<HTMLInputElement | null>(null);
	const form = useForm<ISurveyFormsQuestion>();
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const surveyFormsQuestion = useSelector(selectSurveyFormsQuestion);
	const {
		register,
		handleSubmit,
		control,
		formState,
		watch,
		clearErrors,
		setError,
	} = form;
	const { errors } = formState;
	const { fields, append } = useFieldArray({
		control,
		name: 'options',
	});
	const [data, setData] = useState<ISurveyFormsQuestion>({
		questionId: '',
		questionType: QUECONSTANT.QUESTION_TYPE_OPEN,
		options: [],
		question: '',
		instructions: '',
		order: 0,
		questionIsValid: false,
		questionButtonLabel: commonLabels.save,
	});

	const [isEditable, setIsEditable] = useState(
		item.questionButtonLabel === commonLabels.save ? true : false
	);
	const [label, setLabel] = useState(item.questionButtonLabel);
	const [instructionError, setInstructionError] = useState(false);
	const [charsCountForName, setCharsCountForName] = React.useState(0);
	const remainingCountForQuestionTitle: number =
		QUECONSTANT.MAX_LENGTH_QUESTION_TITLE - charsCountForName;

	const handleDeleteQue = () => {
		onDelete(item?.questionId);
	};
	const handleQueClone = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.options = controlledFields.map((s) => {
			return { options: s?.options, condition: s?.condition };
		});
		onQueClone(newData);
	};

	const questionId = item?.questionId;

	const checkboxState = useSelector((state: RootState) =>
		getCheckBoxState(questionId)(state)
	);

	const handleSaveQue = () => {
		if (questionFormRef?.current) {
			questionFormRef.current.click();
		}
	};

	const handleAddOption = (options: string, condition: string) => {
		append({ options: options, condition: condition });
	};

	const handleConfirmPopup = () => {
		setIsPopupOpen(false);
		return;
	};

	const watchFieldArray = watch('options');
	const controlledFields = fields.map((field, i) => {
		if (watchFieldArray)
			return {
				...field,
				...watchFieldArray[i],
			};
	});

	const updateFormData = (
		prevData: ISurveyFormsQuestion,
		key: string,
		value: string
	) => ({
		...prevData,
		[key]: value,
	});

	const selectCurrentQuestion = () =>
		surveyFormsQuestion.find((question) => question.questionId === questionId)
			?.question;

	const currentQuestion = selectCurrentQuestion();

	useEffect(() => {
		setData((prevData) => ({
			...prevData,
			question: currentQuestion,
		}));
	}, [currentQuestion]);

	useEffect(() => {
		setData(item);
		item.question && setCharsCountForName(item.question.length);
		let options = openOptions;
		if (item.options.length > 0) {
			options = item.options;
		}
		for (const iterator of options) {
			handleAddOption(iterator.options, iterator.condition);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		key: string
	) => {
		setData((prevData) => updateFormData(prevData, key, event.target.value));
		setCharsCountForName(event.target.value.length);
	};

	const onSubmit = (formData: ISurveyFormsQuestion) => {
		// Below parameter are not part of form, need to add in submit object
		formData.questionType = QUECONSTANT.QUESTION_TYPE_OPEN;
		formData.isNew = item.isNew;
		formData.questionId = item.questionId;
		formData.order = item.order;
		formData.instructions = data.instructions;
		formData.question = data.question;
		formData.questionIsValid = false;

		const isDuplicate = isDuplicateQuestion(formData, surveyFormsQuestion);

		if (isDuplicate) {
			setIsPopupOpen(true);
			return;
		}

		// Validation
		const questionValue = data.question;
		const CheckBoxState = checkboxState;
		const Instructions = data.instructions;

		if (CheckBoxState && (!Instructions || Instructions.trim() === '')) {
			alert('Please add instruction');
			formData.questionIsValid = false;
			return;
		}
		if (instructionError) {
			return;
		}
		if (questionValue && questionValue.trim() !== '') {
			setIsEditable(!isEditable);
			const buttonLabel = isEditable ? commonLabels.edit : commonLabels.save;
			setLabel(buttonLabel);
			formData.questionButtonLabel = buttonLabel;
			setIsQuestionEmpty(false);
			formData.questionIsValid = true;
		} else {
			setIsQuestionEmpty(true);
			formData.questionIsValid = false;
		}

		onQueSave(formData, index);

		if (item.isNew) {
			item.isNew = false;
		}
	};

	const updateInstructions = (instructions: string) => {
		setData((prevData) =>
			updateFormData(prevData, 'instructions', instructions)
		);
	};

	const handleInstructionError = (error: boolean) => {
		setInstructionError(error);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} data-testid="question-open-ended">
			<QuestionBox>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<QuestionType>
							<Box>{commonLabels.openEndedQue} </Box>
							<Box>
								<InputCharacterRemaining
									remainingCharacterCount={remainingCountForQuestionTitle}
								/>
							</Box>
						</QuestionType>
						<QuestionTitle>
							<QuestionNumber>{index + 1}.</QuestionNumber>
							<StyledTooltip
								title={commonLabels.questionFieldTooltip}
								placement="top"
							>
								<TextField
									data-testid="question-id"
									className={classes.titleInput}
									InputProps={{ classes: { root: classes.formControlOuter } }}
									margin="dense"
									id="name"
									type="text"
									fullWidth
									disabled={!isEditable}
									value={data.question}
									error={isQuestionEmpty}
									helperText={isQuestionEmpty ? commonLabels.queRequired : ''}
									variant="standard"
									onKeyPress={(event) => {
										const inputValue = (event.target as HTMLInputElement).value;
										if (event.key === ' ' && !inputValue) {
											event.preventDefault();
										}
									}}
									inputProps={{
										maxLength: QUECONSTANT.MAX_LENGTH_QUESTION_TITLE,
									}}
									{...register('question', {
										onChange(event) {
											handleFormChange(event, 'question');
											const inputValue = event.target.value;
											if (inputValue.length === 0) {
												clearErrors('question');
											} else if (
												!inputValue.match(
													VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS
												)
											) {
												setError('question', {
													type: 'manual',
													message:
														commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
												});
											} else {
												clearErrors('question');
											}
										},
										maxLength: {
											value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
											message: commonLabels.queMaxLength,
										},
										pattern: {
											value: VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS,
											message:
												commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
										},
									})}
								/>
							</StyledTooltip>
							{errors.question && (
								<p className={classes.errorMsg}>
									<span className="error-msg">{errors.question?.message}</span>
								</p>
							)}
						</QuestionTitle>
						<QuestionOptions>
							{controlledFields?.map((option, i) => (
								<Box key={option?.id}>
									<FormControl
										className={classes.formControlMultiOuter}
										component="fieldset"
									>
										<Box className={classes.formControlInputBox}>
											<TextField
												disabled
												className={classes.formControlInputCustom}
												margin="dense"
												id="name"
												type="text"
												fullWidth
												variant="standard"
												onKeyPress={(event) => {
													const inputValue = (event.target as HTMLInputElement)
														.value;
													if (event.key === ' ' && !inputValue) {
														event.preventDefault();
													}
												}}
												{...register(`options.${i}.options` as const)}
											/>
										</Box>
										<RemoveOptionBox></RemoveOptionBox>
										{
											<ConfigureOptions
												option={option}
												isEditable={isEditable}
												index={index}
												questions={surveyQuestionnaireInfo.surveyFormsQuestion}
												classN={classes.formControlSelectCustom}
												register={register}
												i={i}
											/>
										}
									</FormControl>
								</Box>
							))}
						</QuestionOptions>
						<Box>
							<QuestionInstruction
								instructions={data.instructions}
								handleError={handleInstructionError}
								onUpdateInstructions={(instructions) =>
									updateInstructions(instructions)
								}
								readonly={!isEditable}
								questionId={item.questionId}
							></QuestionInstruction>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<QuestionActions
							onCloneQue={handleQueClone}
							item={item}
							onSaveQue={handleSaveQue}
							onOpenDelete={handleDeleteQue}
							label={label}
							index={index}
						/>
					</Grid>
				</Grid>
				<input
					data-testid="submit-open-ended"
					ref={questionFormRef}
					type="submit"
					className={classes.submitHide}
				/>
				<GenericConfirmationPopup
					open={isPopUpOpen}
					msgBody={commonLabels.duplicateQuestionMessage}
					title={commonLabels.duplicateQuestionTitle}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleConfirmPopup}
					leftBtnHandler={handleConfirmPopup}
				/>
			</QuestionBox>
		</form>
	);
};
export default QuestionOpenEnded;
