import React from 'react';
import {
	API_STATUS_CONSTANT,
	QUECONSTANT,
} from '../../constants/StepConstants';
import { IResponseGraph } from '../../types/ResultsType';
import { useAppSelector } from '../../app/Hooks';
import {
	fetchDataDisplayType,
	fetchResponseGraphApiStatus,
	responseGraphData,
} from '../../redux/QuestionnaireResultsSlice';
import NoResultContentFound from '../questionnaire-results/NoResultContentFound';
import ErrorContent from '../questionnaire-results/ErrorContent';
import { commonLabels } from '../../utils/CommonLabels';
import {
	getChartSummaryData,
	getGraphData,
	getBarGraphData,
	getYMaxValue,
	getCenterTextForDonut,
} from './ResponseGraphHelpers';
import YesNoAnswers from './types/YesNoAnswers';
import MultipleOptionAnswers from './types/MultipleOptionAnswers';
import DropdownAnswers from './types/DropdownAnswers';

const ResponseGraphComponent: React.FC<IResponseGraph> = ({
	qType,
	value,
	QuesData,
}) => {
	const responseData = useAppSelector(responseGraphData);
	const dataDisplayType = useAppSelector(fetchDataDisplayType);
	const responseApiCompletionStatus = useAppSelector(
		fetchResponseGraphApiStatus
	);
	const isDataAvailable = responseData?.answersData?.length > 0;

	if (isDataAvailable) {
		return (
			<>
				{qType === QUECONSTANT.QUESTION_TYPE_YES_NO && (
					<div data-testid="yes-no-answers">
						<YesNoAnswers
							activeTabValue={value}
							questionJSONString={QuesData}
							chartSummary={getChartSummaryData(
								'donutChart',
								dataDisplayType,
								QuesData,
								responseData?.answersData
							)}
							graphData={getGraphData(
								qType,
								dataDisplayType,
								QuesData,
								responseData?.answersData
							)}
							dataDisplayType={dataDisplayType}
							centerText={getCenterTextForDonut(
								responseData?.answersData,
								dataDisplayType,
								QuesData
							)}
						/>
					</div>
				)}
				{(qType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT ||
					qType === QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION) && (
					<div data-testid="multiple-options">
						<MultipleOptionAnswers
							activeTabValue={value}
							questionJSONString={QuesData}
							chartSummary={getChartSummaryData(
								'barChart',
								dataDisplayType,
								QuesData,
								responseData?.answersData
							)}
							graphData={getBarGraphData(
								dataDisplayType,
								QuesData,
								responseData?.answersData
							)}
							yMax={getYMaxValue(
								QuesData?.questionId,
								responseData?.answersData,
								dataDisplayType
							)}
							dataDisplayType={dataDisplayType}
						/>
					</div>
				)}
				{qType === QUECONSTANT.QUESTION_TYPE_DROPDOWN && (
					<div data-testid="dropdown">
						<DropdownAnswers
							activeTabValue={value}
							questionJSONString={QuesData}
							chartSummary={getChartSummaryData(
								'dropDown-donut',
								dataDisplayType,
								QuesData,
								responseData?.answersData
							)}
							graphData={getGraphData(
								qType,
								dataDisplayType,
								QuesData,
								responseData?.answersData
							)}
							dataDisplayType={dataDisplayType}
							centerText={getCenterTextForDonut(
								responseData?.answersData,
								dataDisplayType,
								QuesData
							)}
						/>
					</div>
				)}
			</>
		);
	} else if (responseApiCompletionStatus === API_STATUS_CONSTANT.FAILED) {
		return <ErrorContent />;
	} else {
		return (
			<NoResultContentFound
				lineOneMessage={commonLabels.noDataGenericQuestion}
			/>
		);
	}
};

const ResponseGraph = React.memo(ResponseGraphComponent);
export default ResponseGraph;
