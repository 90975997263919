import {
	Box,
	Checkbox,
	FormControlLabel,
	MenuItem,
	Select,
	styled,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { STEPTheme } from '../../utils/Theme';
import ChartIndicator from '../questionnaire-results/ChartIndicator';
import { IBarChartCardProps } from '../../types/CommonTypes';
import CustomBarChart from '../questionnaire-results/CustomBarChart';
import { useAppSelector } from '../../app/Hooks';
import {
	groupGraphDataAPIStatus,
	regionalDataApiStatus,
} from '../../redux/QuestionnaireResultsSlice';
import { commonLabels } from '../../utils/CommonLabels';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import { Numbers } from '../../utils/Enum';
import ChipGroup from './ChipGroup';
import Download from './Download';
import CustomDropdown from './CustomDropdown';
import CustomInfoChart from './CustomInfoChart';

const Card = styled(Box)({
	display: 'flex',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	borderRadius: '12px',
	background: '#fff',
	width: '45.5%',
	height: '346px',
	gap: '0px',
	opacity: '0px',
	flexDirection: 'column',
	padding: '20px',
});

const StyledDropdownLabel = styled(Typography)({
	fontSize: '14px',
	fontWeight: 500,
});

const StyledSelectField = styled(Select)({
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '4px',
	borderRadius: '8px',
	minWidth: '300px',
	'&.MuiInputBase-root .MuiSelect-select': {
		padding: '8px 10px',
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.primary,
	},
	'&.Mui-disabled': {
		backgroundColor: `${STEPTheme.colors.disabledButton} !important`,
		borderColor: `${STEPTheme.colors.linkGray} !important`,
	},
});

const BarChartCard = (props: IBarChartCardProps) => {
	const {
		cardKey,
		downloadHandler,
		cardName,
		graphData,
		chipData,
		isChipToShow,
		colors,
		legends,
		chartType,
		isGroupToShow,
		isParameterToShow,
		handleChipSelect,
		graphId,
		height,
		yMax,
		groupList,
		paramList,
		handleGroupSelect,
		handleParameterSelect,
		groupSelected,
		parameterSelected,
		refreshHandler,
	} = props;
	const [graphPlotError, setGraphPlotError] = useState(false);
	const [graphDataEmpty, setGraphDataEmpty] = useState(false);
	const [renderGraph, setRenderGraph] = useState(true);
	const [errorInGroupWiseGraph, setErrorInGroupWiseGraph] = useState(false);
	const groupDataAPIStatus = useAppSelector(groupGraphDataAPIStatus);
	const regionGraphAPIStatus = useAppSelector(regionalDataApiStatus);
	const windowWidth = window.innerWidth;
	const minBarChartWidth = Numbers.sevenSixty;
	const largerScreenWidth = Numbers.fourteenHundredForty;
	const widthMultiplicationFactor = Numbers.zeroPointFiveFive;
	const getBarChartWidth = (newWindowWidth: number) => {
		let barChartContainerWidth = minBarChartWidth;
		if (newWindowWidth >= largerScreenWidth) {
			barChartContainerWidth = newWindowWidth * widthMultiplicationFactor;
		}
		return barChartContainerWidth;
	};
	const [barChartWidth, setBarChartWidth] = useState(
		getBarChartWidth(windowWidth)
	);

	useEffect(() => {
		if (
			(isParameterToShow && groupDataAPIStatus === 'failed') ||
			(isChipToShow && regionGraphAPIStatus === 'failed')
		) {
			setGraphPlotError(true);
			setRenderGraph(false);
		} else {
			setGraphPlotError(false);
			setRenderGraph(true);
		}
		const graphValues: number[] = [];
		graphData.forEach((data) => {
			graphValues.push(...data.values);
		});
		if (graphData.length === 0 && !graphPlotError) {
			setGraphDataEmpty(true);
			setRenderGraph(false);
		}
		const isNoData = graphValues.every((value) => value === 0);
		// if there is no plotting error and the graph data is empty
		if (isNoData && graphValues.length > 0 && !graphPlotError) {
			setGraphDataEmpty(true);
			setRenderGraph(false);
		}

		if (groupList?.length === 0 || paramList?.length === 0) {
			setErrorInGroupWiseGraph(true);
		} else {
			setErrorInGroupWiseGraph(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		groupDataAPIStatus,
		regionGraphAPIStatus,
		graphData,
		groupList,
		paramList,
	]);

	const handleDownload = () => {
		downloadHandler(questionnaireAPIKeys.reportTypeKeyMetrics, cardKey);
	};

	window.addEventListener('resize', () => {
		const newWindowWidth = window.innerWidth;
		const newBarChartWidth = getBarChartWidth(newWindowWidth);

		if (newBarChartWidth) {
			setBarChartWidth(newBarChartWidth);
		}
	});

	const labels = legends?.map((legend) => legend.label);

	const [isGroupAllSelected, setIsGroupAllSelected] = useState(true);
	useEffect(() => {
		handleGroupSelect && handleGroupSelect(Numbers.minusOne.toString());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (groupSelected && groupSelected.length > Numbers.zero) {
			setIsGroupAllSelected(groupList?.length === groupSelected.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupSelected]);

	/**
	 * Click event for Group List all check box
	 */
	const handleAllGroupSelect = (isChecked: boolean) => {
		setIsGroupAllSelected(isChecked);
		if (isChecked) {
			handleGroupSelect && handleGroupSelect(Numbers.minusOne.toString());
		} else {
			handleGroupSelect && handleGroupSelect('');
		}
	};

	const getAllSectionFragment = () => {
		return (
			<MenuItem>
				<FormControlLabel
					control={
						<Checkbox
							value={Numbers.zero}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								handleAllGroupSelect(event.target.checked);
							}}
							checked={isGroupAllSelected}
						/>
					}
					label={commonLabels.allText}
				/>
			</MenuItem>
		);
	};

	const createGroupDropdownList = () => {
		return groupList?.map((dropDownItem) => {
			return (
				<MenuItem key={dropDownItem.key} value={dropDownItem.value}>
					<FormControlLabel
						control={
							<Checkbox
								id={`group_list_${dropDownItem.key}`}
								value={dropDownItem.value}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleGroupSelect && handleGroupSelect(event.target.value);
								}}
								checked={groupSelected?.includes(dropDownItem.value)}
							/>
						}
						label={dropDownItem.value}
						sx={{
							width: '100%',
							padding: '0',
						}}
					/>
				</MenuItem>
			);
		});
	};

	return (
		<Card>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant="h2">{cardName}</Typography>
				<Box>
					<Box>
						<Download type="icon" handleDownload={handleDownload} />
					</Box>
				</Box>
			</Box>
			{isChipToShow && (
				<ChipGroup
					data-testId="group"
					chips={chipData ?? []}
					color={STEPTheme.colors.blue}
					backgroundColor={STEPTheme.colors.powderBlue}
					onSelect={(chipName: string) => {
						handleChipSelect && handleChipSelect(chipName);
					}}
					preSelectedDays={Numbers.zero}
				/>
			)}
			{!errorInGroupWiseGraph && (
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					{isGroupToShow && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<StyledDropdownLabel>{commonLabels.groupTxt}</StyledDropdownLabel>
							<StyledSelectField
								id="group-wise-graph"
								data-testid="group-wise-graph"
								placeholder={commonLabels.selectLabel}
								displayEmpty
								multiple
								value={[]}
								autoWidth={true}
								renderValue={() =>
									groupSelected && groupSelected?.length > Numbers.zero
										? `${groupSelected?.length} ${commonLabels.selected}`
										: commonLabels.selectLabel
								}
							>
								{getAllSectionFragment()}
								{createGroupDropdownList()}
							</StyledSelectField>
						</Box>
					)}
					{isParameterToShow && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<StyledDropdownLabel>
								{commonLabels.filterTxt}
							</StyledDropdownLabel>
							<CustomDropdown
								data-testid="parameter"
								value={parameterSelected ?? ''}
								valueList={paramList ?? []}
								handleChange={(e) => {
									handleParameterSelect && handleParameterSelect(e);
								}}
								width={'15vw'}
							/>
						</Box>
					)}
				</Box>
			)}
			{renderGraph && (
				<Box
					sx={{
						display: 'flex',
						gap: 2,
						justifyContent: 'center',
						paddingTop: '20px',
					}}
				>
					{legends?.map((legend) => {
						return (
							<ChartIndicator
								key={legend.key}
								label={legend.label}
								color={legend.color}
							/>
						);
					})}
				</Box>
			)}
			{renderGraph && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						height: '346px',
						opacity: '0px',
						overflow: 'hidden',
					}}
				>
					<CustomBarChart
						width={barChartWidth}
						height={height}
						data={graphData}
						chartType={chartType}
						colors={colors}
						id={graphId}
						yMax={yMax}
						labels={labels}
					/>
				</Box>
			)}
			{graphPlotError && !renderGraph && (
				<CustomInfoChart
					type="error"
					handleRefresh={refreshHandler}
					titleText={commonLabels.errorText}
					bodyText={commonLabels.graphRenderErrorText}
				/>
			)}
			{graphDataEmpty && !graphPlotError && !renderGraph && (
				<CustomInfoChart
					type="info"
					titleText={commonLabels.noDataFound}
					bodyText={commonLabels.noDataGraphTxt}
				/>
			)}
		</Card>
	);
};

export default BarChartCard;
