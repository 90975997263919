import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ISurveyFormsQuestion, SurveyOptions } from '../../types/SurveyProps';
import {
	MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { commonLabels } from '../../utils/CommonLabels';
import { selectSurveyFormsQuestion } from '../../redux/SurveySlice';
import { RootState } from '../../app/Store';
import { getCheckBoxState } from '../../redux/QuestionnaireSurveySlice';
import StyledTooltip from '../StyledTooltip';
import { isDuplicateQuestion } from '../../utils/CommonMethods';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import QuestionUpload from './QuestionUpload';
import QuestionActions from './QuestionActions';
import QuestionInstruction from './QuestionInstruction';
import InputCharacterRemaining from './InputCharacterRemaining';

const useStyles = makeStyles({
	submitHide: {
		display: 'none',
	},
	titleInput: {
		flex: '1 0 33%',
	},
	errorMsg: {
		flexBasis: '100%',
		margin: '0',
		paddingLeft: '15px',
	},
	errorOptionMsg: {
		margin: '0',
	},
	formControlOuter: {
		marginTop: '0',
		width: '100%',
		display: 'flex',
		'&.MuiFormControl-root': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	formControlInputCustom: {
		'&.MuiFormControl-root': {
			width: '51%',
		},
	},
	formControlInputIns: {
		'&.MuiFormControl-root': {
			width: '100%',
			marginLeft: '15px',
		},
	},
	formControlSelectCustom: {
		width: '34%',
	},
	formControlMultiOuter: {
		marginTop: '0',
		width: '100%',
		display: 'flex',
		'&.MuiFormControl-root': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
});
const QuestionBox = styled(Box)({
	display: 'flex',
	flex: '2 1 auto',
	padding: '10px 10px 10px 20px',
});
const QuestionTitle = styled(Box)({
	display: 'flex',
	marginTop: '0px',
	alignItems: 'end',
	height: 'auto',
	flexWrap: 'wrap',
	fontWeight: '400',
	fontSize: '16px',
	lineHeight: '24px',
	fontFamily: '"JohnsonText-Regular"',
});
const QuestionType = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '14px',
	lineHeight: '12px',
	fontWeight: '500',
	display: 'flex',
	justifyContent: 'space-between',
	paddingLeft: '15px',
});
const QuestionNumber = styled(Box)({
	paddingBottom: '5px',
	paddingRight: '5px',
	flex: '0 1 0',
});
const QuestionOptions = styled(Box)({
	paddingLeft: '15px',
});
const QuestionDropDownList: React.FC<{
	item: ISurveyFormsQuestion;
	index: number;
	onDelete: (i: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion, index: number) => void;
}> = ({ item, index, onDelete, onQueClone, onQueSave }) => {
	const dropdownOptions: SurveyOptions[] = [{ options: '', condition: '' }];
	const classes = useStyles();
	const questionFormRef = useRef<HTMLInputElement | null>(null);
	const form = useForm<ISurveyFormsQuestion>();
	const {
		register,
		handleSubmit,
		control,
		formState,
		watch,
		setError,
		clearErrors,
	} = form;
	const { errors } = formState;
	const [isSaveClicked, setIsSaveClicked] = useState(false);
	const [isPopUpOpen, setIsPopupOpen] = useState(false);
	const [isFileNotUploaded, setIsFileNotUploaded] = useState(true);
	const [instructionError, setInstructionError] = useState(false);
	const [isQuestionEmpty, setIsQuestionEmpty] = useState(true);
	const surveyFormsQuestion = useSelector(selectSurveyFormsQuestion);
	const { fields, append } = useFieldArray({
		control,
		name: 'options',
	});
	const [data, setData] = useState<ISurveyFormsQuestion>({
		questionId: '',
		questionType: QUECONSTANT.QUESTION_TYPE_DROPDOWN,
		options: [],
		question: '',
		instructions: '',
		order: 0,
		questionIsValid: false,
		questionButtonLabel: commonLabels.save,
		fileName: '',
	});
	const [charsCountForName, setCharsCountForName] = React.useState(0);
	const remainingCountForQuestionTitle: number =
		QUECONSTANT.MAX_LENGTH_QUESTION_TITLE - charsCountForName;
	const [isEditable, setIsEditable] = useState(
		item.questionButtonLabel === commonLabels.save ? true : false
	);
	const [label, setLabel] = useState(item.questionButtonLabel);
	const handleDeleteQue = () => {
		onDelete(item?.questionId);
	};
	const handleQueClone = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.options = controlledFields.map((s) => {
			return { options: s?.options, condition: s?.condition };
		});
		onQueClone(newData);
	};

	const questionId = item?.questionId;

	const checkboxState = useSelector((state: RootState) =>
		getCheckBoxState(questionId)(state)
	);

	const handleSaveQue = () => {
		setIsSaveClicked(true);
		if (questionFormRef?.current) {
			questionFormRef.current.click();
		}
	};

	const handleAddOption = (options: string, condition: string) => {
		append({ options: options, condition: condition });
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const watchFieldArray = watch('options');
	const controlledFields = fields.map((field, i) => {
		if (watchFieldArray)
			return {
				...field,
				...watchFieldArray[i],
			};
	});
	const updateFormData = (
		prevData: ISurveyFormsQuestion,
		key: string,
		value: string
	) => ({
		...prevData,
		[key]: value,
	});

	const selectCurrentQuestion = () =>
		surveyFormsQuestion.find((question) => question.questionId === questionId)
			?.question;

	const currentQuestion = selectCurrentQuestion();

	useEffect(() => {
		setData((prevData) => ({
			...prevData,
			question: currentQuestion,
		}));
	}, [currentQuestion]);

	useEffect(() => {
		setData(item);
		item.question && setCharsCountForName(item.question.length);
		let options = dropdownOptions;
		if (item?.options?.length > 0) {
			options = item.options;
		}
		for (const iterator of options) {
			handleAddOption(iterator.options, iterator.condition);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		key: string
	) => {
		setData((prevData) => updateFormData(prevData, key, event.target.value));
		setCharsCountForName(event.target.value.length);
	};

	const handleExcelData = (excelStringData: {
		excelData: string;
		filename: string;
	}) => {
		const values = JSON.parse(excelStringData.excelData);
		setData((prevData) => updateFormData(prevData, 'options', values));
		setData((prevData) =>
			updateFormData(prevData, 'fileName', excelStringData.filename)
		);
		setIsFileNotUploaded(false);
	};

	const handleExcelDelete = () => {
		setData((prevData) => updateFormData(prevData, 'options', ''));
		setData((prevData) => updateFormData(prevData, 'fileName', ''));
	};

	const handleConfirmPopup = () => {
		setIsPopupOpen(false);
		return;
	};

	const onSubmit = (formData: ISurveyFormsQuestion) => {
		// Below parameters are not part of form, need to add in submit object
		formData.questionType = QUECONSTANT.QUESTION_TYPE_DROPDOWN;
		formData.isNew = item.isNew;
		formData.questionId = item.questionId;
		formData.order = item.order;
		formData.options = data?.options?.map((option: SurveyOptions) => {
			return {
				...option,
				condition: option?.condition || '',
			};
		});
		formData.instructions = data.instructions;
		formData.question = data.question;

		const isDuplicate = isDuplicateQuestion(formData, surveyFormsQuestion);

		if (isDuplicate) {
			setIsPopupOpen(true);
			return;
		}

		// Validation
		const questionValue = data?.question;
		const CheckBoxState = checkboxState;
		const Instructions = data.instructions;
		let questionValid = true;
		const isFileValid = data?.options?.length > 0;
		if (!questionValue && !isFileValid) {
			setIsQuestionEmpty(true);
			setIsFileNotUploaded(true);
			questionValid = false;
		} else if (questionValue && !isFileValid) {
			setIsQuestionEmpty(false);
			setIsFileNotUploaded(true);
			questionValid = false;
		} else if (!questionValue && isFileValid) {
			setIsQuestionEmpty(true);
			setIsFileNotUploaded(false);
			questionValid = false;
		} else {
			setIsQuestionEmpty(false);
			setIsFileNotUploaded(false);
		}
		if (CheckBoxState && (!Instructions || Instructions.trim() === '')) {
			questionValid = false;
			alert('Please add instruction');
		}
		if (instructionError) {
			return;
		}
		if (questionValue && questionValue.trim() !== '') {
			setIsQuestionEmpty(false);
		}

		if (questionValid) {
			setIsEditable(!isEditable);
			const buttonLabel = isEditable ? commonLabels.edit : commonLabels.save;
			setLabel(buttonLabel);
			formData.questionButtonLabel = buttonLabel;
			setIsQuestionEmpty(false);
			setIsFileNotUploaded(false);
		}
		formData.questionIsValid = questionValid;
		formData.fileName = data?.fileName;
		onQueSave(formData, index);
		updateIsNew(item);
	};
	const updateIsNew = (itemObj: ISurveyFormsQuestion) => {
		if (itemObj.isNew) {
			itemObj.isNew = false;
		}
	};
	const updateInstructions = (instructions: string) => {
		setData((prevData) =>
			updateFormData(prevData, 'instructions', instructions)
		);
	};
	const handleInstructionError = (error: boolean) => {
		setInstructionError(error);
	};
	const checkForHelpText = () => {
		if (isSaveClicked) {
			if (isQuestionEmpty && isFileNotUploaded) {
				return commonLabels.pleaseUploadFileAndQuestion;
			} else if (data?.question?.trim() === '') {
				return commonLabels.queRequired;
			} else if (isFileNotUploaded) {
				return commonLabels.fileRequired;
			} else {
				return '';
			}
		}
		return '';
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} data-testid="question-dropdown">
			<QuestionBox>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<Box>
							<QuestionType>
								<Box>{commonLabels.dropDownQuestion} </Box>
								<Box>
									<InputCharacterRemaining
										remainingCharacterCount={remainingCountForQuestionTitle}
									/>
								</Box>
							</QuestionType>
							<QuestionTitle>
								<QuestionNumber>{index + 1}.</QuestionNumber>
								<StyledTooltip
									title={commonLabels.questionFieldTooltip}
									placement="top"
								>
									<TextField
										data-testid="question-id"
										className={classes.titleInput}
										InputProps={{ classes: { root: classes.formControlOuter } }}
										margin="dense"
										id="name"
										disabled={!isEditable}
										type="text"
										value={data.question}
										variant="standard"
										onKeyPress={(event) => {
											const inputValue = (event.target as HTMLInputElement)
												.value;
											if (event.key === ' ' && !inputValue) {
												event.preventDefault();
											}
										}}
										error={
											(isQuestionEmpty || isFileNotUploaded) && isSaveClicked
										}
										helperText={checkForHelpText()}
										inputProps={{
											maxLength: QUECONSTANT.MAX_LENGTH_QUESTION_TITLE,
										}}
										{...register('question', {
											onChange(event) {
												handleFormChange(event, 'question');
												const inputValue = event.target.value;
												if (inputValue.length === 0) {
													clearErrors('question');
												} else if (
													!inputValue.match(
														VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS
													)
												) {
													setError('question', {
														type: 'manual',
														message:
															commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
													});
												} else {
													clearErrors('question');
												}
											},
											maxLength: {
												value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
												message: commonLabels.queMaxLength,
											},
											pattern: {
												value: VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS,
												message:
													commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
											},
										})}
									/>
								</StyledTooltip>
								{errors.question && (
									<p className={classes.errorMsg}>
										<span className="error-msg">
											{errors.question?.message}
										</span>
									</p>
								)}
							</QuestionTitle>
						</Box>
						<QuestionOptions>
							<QuestionUpload
								index={index}
								onFileUploadExcelData={handleExcelData}
								item={item}
								readonly={!isEditable}
								onFileRemoved={handleExcelDelete}
							></QuestionUpload>
						</QuestionOptions>
						<QuestionInstruction
							instructions={data.instructions}
							handleError={handleInstructionError}
							onUpdateInstructions={(instructions) =>
								updateInstructions(instructions)
							}
							readonly={!isEditable}
							questionId={item.questionId}
						></QuestionInstruction>
					</Grid>
					<Grid item xs={2}>
						<Box>
							<QuestionActions
								onCloneQue={handleQueClone}
								item={item}
								onSaveQue={handleSaveQue}
								onOpenDelete={handleDeleteQue}
								label={label}
								index={index}
							/>
						</Box>
					</Grid>
				</Grid>
				<input
					data-testid="submit-dropdown"
					ref={questionFormRef}
					type="submit"
					className={classes.submitHide}
				/>
				<GenericConfirmationPopup
					open={isPopUpOpen}
					msgBody={commonLabels.duplicateQuestionMessage}
					title={commonLabels.duplicateQuestionTitle}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleConfirmPopup}
					leftBtnHandler={handleConfirmPopup}
				/>
			</QuestionBox>
		</form>
	);
};
export default QuestionDropDownList;
