import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
	Box,
	Card,
	CardContent,
	FormGroup,
	Checkbox,
	TextField,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	MenuItem,
	Select,
	Button,
	InputLabel,
	Menu,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@material-ui/core';
import { ISurveyFormsDetails } from '../../types/SurveyProps';
import SURVEY_DRAW_LOGO from '../../assets/images/survey-draw.svg';
import { surveyFormDropDown } from '../../utils/Filter';
import StyledTooltip from '../../components/StyledTooltip';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	surveyQuestionnaireData,
	updateSurveyQuestionnaire,
	configurationData,
	updateQuestionnaireData,
	resetAPIFlag,
	APILoadingStatus,
	errorMessage,
	SetErrorMessage,
} from '../../redux/SurveySlice';
import {
	MAX_LENGTH_QUESTIONNAIRE_TITLE,
	MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
	BASIC_CONSTANT,
	API_STATUS_CONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { Numbers, QUE_JOURNEY_STEPS } from '../../utils/Enum';
import QuestionFooter from '../../components/questions/QuestionFooter';
import GenericModalPopup from '../../components/common/GenericModalPopup';
import { STEPTheme } from '../../utils/Theme';
import { commonLabels } from '../../utils/CommonLabels';
import { getQuestionnairePayload } from '../../utils/Helpers';
import Loader from '../../components/Loader';
import CustomDropdown from '../../components/common/CustomDropdown';

const useStyles = makeStyles({
	astrickClass: {
		color: STEPTheme.colors.red,
	},
	quessionariesForMenu: {
		borderRadius: '12px',
		minWidth: '320px',
		'& .MuiPaper-root': {
			borderRadius: '12px',
			minWidth: '320px',
		},
	},
	quessionariesForBtn: {
		fontFamily: "'JohnsonText-Regular' !important",
		fontSize: '16px !important',
		lineHeight: '16px',
		background: STEPTheme.colors.white,
		border: '1px solid #cecece !important',
		width: '100%',
		justifyContent: 'left !important',
		padding: '7px 15px !important',
		textTransform: 'none',
		borderRadius: '8px',
		height: '40px',
		maxWidth: '322px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		color: `${STEPTheme.colors.darkGray} !important`,
		position: 'relative',
		'& .MuiTouchRipple-root': {
			position: 'absolute',
			content: '',
			width: '0',
			height: '0',
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent',
			borderTop: `5px solid ${STEPTheme.colors.arrowGray}`,
			right: '15px',
			top: '17px',
			left: 'auto',
		},
		'&:hover': {
			background: `${STEPTheme.colors.white} !important`,
			border: `1px solid ${STEPTheme.colors.black} !important`,
		},
	},
	labelDate: {
		fontFamily: '"JohnsonText-Regular"',
		fontSize: '16px',
		lineHeight: '16px',
		fontWeight: 400,
	},
});

const StyledWholeQuestionnaireContainer = styled(Box)({
	backgroundColor: STEPTheme.colors.background,
});

const StyledFormControl = styled(FormControl)({
	margin: '0px',
	width: '100%',
});

const QuestionnaireForTextField = styled(TextField)({
	display: 'none',
});

const QuestionnaireForRadioGroup = styled(RadioGroup)({
	marginLeft: '10px',
});

const StyledQuestionnaireAddContainer = styled('div')({
	backgroundColor: STEPTheme.colors.background,
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});

const StyleFormContainer = styled('div')({
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '0px',
});
const StyledButtonsContainer = styled(Box)({
	width: '90%',
	gap: '20px',
	display: 'inline-flex',
	justifyContent: 'flex-end',
	marginInline: 'auto',
	paddingBottom: '20px',
});

const StyledLogo = styled('img')({
	width: '295px',
	height: '662px',
	marginLeft: '108px',
	zIndex: 100,
	position: 'absolute',
});
const ButtonLabel = styled(Box)({
	display: 'inline-block',
	width: 'calc(100% - 15px)',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});
const StyledTextField = styled(TextField)({
	width: '322px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	display: 'inline-flex',
	justifyContent: 'flex-end',
	'& .MuiInputBase-root': {
		height: '40px',
		borderRadius: '8px',
	},
});

const StyledTextFieldDescription = styled(TextField)({
	width: '322px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	'& .MuiInputBase-root': {
		height: '100px',
		borderRadius: '8px',
	},
});

const StyledSelectField = styled(Select)({
	width: '322px',
	height: '40px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '2px',
	borderRadius: '8px',
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.primary,
	},
	'& .MuiInputBase-input::placeholder': {
		fontFamily: '"JohnsonText-Regular"',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '24px',
	},
});

const QuessionariesForLabel = styled(FormControlLabel)({
	'& .MuiTypography-root': {
		fontWeight: 'normal',
	},
});

const ApplyTextLabel = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '14px',
	color: STEPTheme.colors.text,
	lineHeight: '12px',
	fontWeight: '500',
	marginBottom: '4px',
	marginTop: '7px',
	textTransform: 'capitalize',
	display: 'flex',
	justifyContent: 'space-between',
});
interface CreateQuestionnaireDetailsProps {
	handleNext: () => void;
}
const CreateSurveyDetails: React.FC<CreateQuestionnaireDetailsProps> = ({
	handleNext,
}) => {
	const [open, setOpen] = React.useState(false);
	const [openErrorPopup, setOpenErrorPopup] = React.useState(false);
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const form = useForm<ISurveyFormsDetails>();
	const {
		register,
		handleSubmit,
		control,
		formState,
		getValues,
		setValue,
		setError,
		clearErrors,
	} = form;
	const { errors } = formState;
	const [checkBoxValue, setCheckBoxValue] = React.useState<string[]>([]);
	const [surveyStartDate, setSurveyStartDate] = React.useState<Date | null>(
		null
	);
	const [surveyDueDate, setSurveyDueDate] = React.useState<Date | null>(null);
	const [value, setValueChecked] = React.useState('');
	const [questionnaireFor, setQuestionnaireFor] = React.useState('');
	const [surveyType, setSurveyType] = React.useState('');
	const [category, setCategory] = React.useState('');
	const [duration, setDuration] = React.useState('');
	const [questionnaireForRadio, setQuestionnaireForRadio] = React.useState('');
	const [dropdownElement, setDropdownElement] =
		React.useState<null | HTMLElement>(null);
	const openDropdown = Boolean(dropdownElement);
	const [charsCountForTitle, setCharsCountForTitle] = React.useState(0);
	const [charsCountForDescription, setCharsCountForDescription] =
		React.useState(0);
	const remainingCountForQuestionnaireTitle =
		MAX_LENGTH_QUESTIONNAIRE_TITLE - charsCountForTitle;

	const remainingCountForQuestionnaireDescription =
		MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION - charsCountForDescription;
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const configs = useAppSelector(configurationData);
	const [showLoader, setShowLoader] = React.useState(false);
	const [isSaveProceed, setIsSaveProceed] = React.useState(false);
	const APICompletionStatus = useAppSelector(APILoadingStatus);
	const errMsgInSaveAsDraft = useAppSelector(errorMessage);
	useEffect(() => {
		if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
			setShowLoader(false);

			if (isSaveProceed) {
				// Resetting the flag for this scenario here as required only in this scenario
				dispatch(resetAPIFlag('idle'));
				handleNext();
			} else {
				setOpen(true);
			}
		} else if (
			errMsgInSaveAsDraft &&
			errMsgInSaveAsDraft?.code === API_STATUS_CONSTANT.ERROR_BAD_REQUEST
		) {
			setShowLoader(false);
			setOpenErrorPopup(true);
		} else {
			dispatch(resetAPIFlag('idle'));
			setOpen(false);
			setShowLoader(false);
			setIsSaveProceed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	useEffect(() => {
		if (surveyQuestionnaireInfo.shouldTriggerAPI) {
			// To trigger an API call with the transformed Redux data
			const payload = getQuestionnairePayload({
				...surveyQuestionnaireInfo,
				userEmailId: configs?.user?.userEmailId || '',
			});
			dispatch(updateQuestionnaireData(payload));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyQuestionnaireInfo, configs]);

	const onSubmit: SubmitHandler<ISurveyFormsDetails> = (
		formData: ISurveyFormsDetails
	) => {
		if (Object.keys(errors)?.length === 0) {
			setIsSaveProceed(true);
			setShowLoader(true);
			// To save in the Redux state
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS,
					surveyFormsDetails: {
						...surveyQuestionnaireInfo.surveyFormsDetails,
						...formData,
					},
					shouldTriggerAPI: true,
				})
			);
		}
	};

	const handleClosePopup = () => {
		setOpen(false);
		dispatch(resetAPIFlag('idle'));
	};
	const handleCloseErrorPopup = () => {
		setOpenErrorPopup(false);
		dispatch(SetErrorMessage({ code: '', errorMessage: '' }));
		dispatch(resetAPIFlag('idle'));
	};

	const handleSaveDraft = () => {
		if (Object.keys(errors)?.length === 0) {
			setIsSaveProceed(false);
			setShowLoader(true);
			const formValues = getValues();
			// To save in the Redux state
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS,
					surveyFormsDetails: {
						...surveyQuestionnaireInfo.surveyFormsDetails,
						...formValues,
					},
					shouldTriggerAPI: true,
				})
			);
		}
	};

	const handleCharacterCountTitle = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setCharsCountForTitle(event.target.value.length);
		clearErrors('surveyName');
	};

	const handleCharacterCountDescription = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setCharsCountForDescription(event.target.value.length);
		clearErrors('surveyDescription');
	};

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValueChecked((event.target as HTMLInputElement).value);
		if (event.target.value === BASIC_CONSTANT.DUE_DATE) {
			setDuration('');
			setValue('surveyDuration', 0);
		} else {
			setSurveyDueDate(null);
			setValue('surveyDueDate', null);
		}
		clearErrors('surveyDueDate');
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setDropdownElement(event.currentTarget);
	};

	const handleClose = () => {
		setDropdownElement(null);
	};

	useEffect(() => {
		const formData = surveyQuestionnaireInfo.surveyFormsDetails;
		if (formData.id) {
			/* eslint-disable @typescript-eslint/no-explicit-any */
			Object.entries(formData).forEach(([name, value]: any) => {
				if (name !== 'questionnaireForRadio') setValue(name, value);
			});
			setInitialValues(formData);
			setQuestionnairesForInitialValues(formData);
			setDurationInitialValues(formData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyQuestionnaireInfo]);

	const setInitialValues = (formData: ISurveyFormsDetails) => {
		setSurveyType(formData.surveyType);
		setQuestionnaireFor(formData.questionnaireFor);
		setCategory(formData.surveyOwningLocation);
		setSurveyStartDate(formData.surveyStartDate);
		setSurveyDueDate(formData.surveyDueDate);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setQuestionnairesForInitialValues = (formData: ISurveyFormsDetails) => {
		if (formData?.surveyDuration) {
			setDuration(formData.surveyDuration.toString());
			setValue('surveyDuration', formData.surveyDuration);
		}
		if (formData?.questionnaireFor === BASIC_CONSTANT.SELF) {
			setQuestionnaireForRadio(BASIC_CONSTANT.SELF);
			setValue('questionnaireForRadio', BASIC_CONSTANT.SELF);
		} else if (
			formData?.questionnaireFor === BASIC_CONSTANT.CONTINGENT ||
			BASIC_CONSTANT.DIRECT
		) {
			setQuestionnaireForRadio(BASIC_CONSTANT.OTHERS);
			setCheckBoxValue(formData.questionnaireFor?.split(','));
			setQuestionnaireFor(checkBoxValue.toString());
			setValue('questionnaireFor', checkBoxValue.toString());
		} else {
			setQuestionnaireForRadio('');
		}
	};

	const setDurationInitialValues = (formData: ISurveyFormsDetails) => {
		if (formData.surveyDuration) {
			setValueChecked(BASIC_CONSTANT.DURATION);
		} else if (formData.surveyDueDate) {
			setValueChecked(BASIC_CONSTANT.DUE_DATE);
		} else {
			setValueChecked('');
		}
	};

	useEffect(() => {
		setValue('questionnaireForRadio', questionnaireForRadio);
		if (
			questionnaireForRadio &&
			questionnaireForRadio === BASIC_CONSTANT.SELF
		) {
			setQuestionnaireFor(questionnaireForRadio);
			setValue('questionnaireFor', questionnaireForRadio);
			setCheckBoxValue([]);
		} else if (
			questionnaireForRadio &&
			questionnaireForRadio === BASIC_CONSTANT.OTHERS
		) {
			setQuestionnaireFor(checkBoxValue.toString());
			setValue('questionnaireFor', checkBoxValue.toString());
		} else {
			setQuestionnaireFor('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireForRadio, checkBoxValue, questionnaireFor]);

	const handleRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuestionnaireForRadio((event.target as HTMLInputElement).value);
		clearErrors('questionnaireFor');
	};
	const handleQuestionnaireType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSurveyType(event.target.value);
		clearErrors('surveyType');
	};

	const handleCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCategory(event.target.value);
		clearErrors('surveyOwningLocation');
	};

	const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checkboxValue = event.target.value;
		const isChecked = event.target.checked;
		if (isChecked) {
			setCheckBoxValue([...checkBoxValue, checkboxValue]);
		} else {
			setCheckBoxValue(checkBoxValue.filter((e) => e !== checkboxValue));
		}
	};
	const handleDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDuration(event.target.value);
		clearErrors('surveyDuration');
	};

	const getQuestionnaireType = () => {
		if (value === 'dueDate') {
			return (
				<>
					<ApplyTextLabel>
						{' '}
						<span>
							{surveyFormDropDown.label.SET_DATE}{' '}
							<span className={classes.astrickClass}>*</span>
						</span>
					</ApplyTextLabel>

					<StyledFormControl data-testid="duedate-field">
						<Controller
							control={control}
							name="surveyDueDate"
							render={({
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								field: { ref, onBlur, name, onChange, ...field },
							}) => (
								<DatePicker
									minDate={
										surveyStartDate
											? new Date(
													surveyStartDate.getTime() +
														Numbers.TIME_ONE_DAY_IN_MILLI_SECS
												)
											: new Date(
													new Date().getTime() +
														Numbers.twentyFour *
															Numbers.sixty *
															Numbers.sixty *
															Numbers.thousand
												)
									}
									{...field}
									{...register('surveyDueDate', {
										valueAsDate: true,
										required: {
											value: true,
											message: 'End date is required',
										},
									})}
									format="dd/MM/yyyy"
									value={surveyDueDate}
									onChange={(newValue) => {
										setSurveyDueDate(newValue);
										setValue('surveyDueDate', newValue);
										clearErrors('surveyDueDate');
									}}
									sx={{
										'&.MuiFormControl-root-MuiTextField-root': {
											width: '322px',
											border: '4px',
											marginTop: '2px',
										},
										'.MuiInputBase-input': {
											paddingTop: '10px ',
											paddingBottom: '10px',
											height: '1.2em',
										},
										'.MuiOutlinedInput-root': {
											borderRadius: '8px',
										},
									}}
									slotProps={{
										field: {
											readOnly: true,
										},
									}}
								/>
							)}
						/>
					</StyledFormControl>
					{errors.surveyDueDate && (
						<p className="error-msg">{errors.surveyDueDate?.message}</p>
					)}
				</>
			);
		} else if (value === 'duration') {
			return (
				<>
					<ApplyTextLabel>
						<span>
							{' '}
							{surveyFormDropDown.label.SET_DURTION}
							<span className={classes.astrickClass}>*</span>
						</span>
					</ApplyTextLabel>
					<StyledFormControl sx={{ minWidth: 120 }}>
						{configs?.duration && (
							<CustomDropdown
								valueList={configs.duration}
								register={register}
								value={duration}
								registerName={'surveyDuration'}
								validationMessage={'Duration is required'}
								handleChange={handleDuration}
							/>
						)}
					</StyledFormControl>
					{errors.surveyDuration && (
						<p className="error-msg">{errors.surveyDuration?.message}</p>
					)}
				</>
			);
		}
		return '';
	};

	return (
		<>
			<Loader isLoading={showLoader} />
			<StyledWholeQuestionnaireContainer>
				<StyledLogo
					src={SURVEY_DRAW_LOGO}
					className="survey-draw-logo"
					title={commonLabels.questionnaire}
					alt={commonLabels.questionnaire}
				/>
				<StyledQuestionnaireAddContainer>
					<Box
						sx={{
							fontWeight: '700',
							fontSize: '25px',
							lineHeight: '32px',
							fontFamily: 'JohnsonDisplay-Bold',
							width: '210px',
							height: '32px',
							marginTop: '15px',
							marginRight: '81px',
							whiteSpace: 'nowrap',
						}}
					>
						{commonLabels.addQuestionnaireDetails}
					</Box>

					<StyleFormContainer>
						<Card
							sx={{ marginTop: '22px', borderRadius: '16px', padding: '20px' }}
						>
							<CardContent>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<form onSubmit={handleSubmit(onSubmit)} noValidate>
										<StyledTooltip
											title={commonLabels.questionnaireNameTooltip}
											placement="right"
										>
											<ApplyTextLabel>
												<span>
													{surveyFormDropDown.label.QUESTIONNAIRE_NAME}
													<span className={classes.astrickClass}>*</span>
												</span>
												<Box
													sx={{
														fontWeight: '400',
														fontSize: '10px',
														lineHeight: '24px',
														fontStyle: 'italic',
														fontFamily: '"JohnsonText-Regular"',
														height: '10px',
														paddingBottom: '4px',
													}}
												>{`${remainingCountForQuestionnaireTitle} Characters`}</Box>
											</ApplyTextLabel>
										</StyledTooltip>
										<InputLabel sx={{ MuiInputLabel: 'asterisk' }} />

										<StyledFormControl sx={{ minWidth: 120 }}>
											<StyledTextField
												data-testid="name-field"
												placeholder="Enter Text"
												required
												onKeyPress={(event) => {
													const inputValue = (event.target as HTMLInputElement)
														.value;
													if (event.key === ' ' && !inputValue) {
														event.preventDefault();
													}
												}}
												inputProps={{
													maxLength: MAX_LENGTH_QUESTIONNAIRE_TITLE,
												}}
												{...register('surveyName', {
													required: commonLabels.questionnaireNameRequired,
													maxLength: {
														value: MAX_LENGTH_QUESTIONNAIRE_TITLE,
														message: commonLabels.questionnaireChar,
													},
													pattern: {
														value: VALIDATIONS_RULES.VALIDATION_QUESTION_TITLE,
														message:
															commonLabels.incorrectQuestionnaireNameFormatErrorMsg,
													},
												})}
												onChange={(event) => {
													handleCharacterCountTitle(event);
													const inputValue = event.target.value;
													if (inputValue.length === 0) {
														clearErrors('surveyName');
													} else if (
														!inputValue.match(
															VALIDATIONS_RULES.VALIDATION_QUESTION_TITLE
														)
													) {
														setError('surveyName', {
															type: 'manual',
															message:
																commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
														});
													} else {
														clearErrors('surveyName');
													}
												}}
											></StyledTextField>
										</StyledFormControl>
										{errors.surveyName && (
											<p className="error-msg">{errors.surveyName?.message}</p>
										)}
										<StyledTooltip
											title={commonLabels.questionniareDescriptionTooltip}
											placement="right"
										>
											<ApplyTextLabel>
												<span>
													{' '}
													{surveyFormDropDown.label.QUESTIONNAIRE_DESCRIPTION}
													<span className={classes.astrickClass}>*</span>
												</span>
												<Box
													sx={{
														fontWeight: '400',
														fontSize: '10px',
														lineHeight: '24px',
														fontStyle: 'italic',
														fontFamily: '"JohnsonText-Regular"',
														height: '10px',
														paddingBottom: '4px',
													}}
												>{`${remainingCountForQuestionnaireDescription} Characters`}</Box>
											</ApplyTextLabel>
										</StyledTooltip>
										<StyledFormControl sx={{ minWidth: 120 }}>
											<StyledTextFieldDescription
												data-testid="description-field"
												multiline
												rows={4}
												placeholder="Enter Text"
												required
												onKeyPress={(event) => {
													const inputValue = (event.target as HTMLInputElement)
														.value;
													if (event.key === ' ' && !inputValue) {
														event.preventDefault();
													}
												}}
												inputProps={{
													maxLength: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
												}}
												{...register('surveyDescription', {
													required: commonLabels.questionnaireDes,
													maxLength: {
														value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
														message: commonLabels.questionnaireDesChar,
													},
													pattern: {
														value:
															VALIDATIONS_RULES.VALIDATION_QUESTION_DESCRIPTION,
														message:
															commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
													},
												})}
												onChange={(event) => {
													handleCharacterCountDescription(event);
													const inputValue = event.target.value;
													if (inputValue.length === 0) {
														clearErrors('surveyDescription');
													} else if (
														!inputValue.match(
															VALIDATIONS_RULES.VALIDATION_QUESTION_DESCRIPTION
														)
													) {
														setError('surveyDescription', {
															type: 'manual',
															message:
																commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
														});
													} else {
														clearErrors('surveyDescription');
													}
												}}
											></StyledTextFieldDescription>
										</StyledFormControl>
										{errors.surveyDescription && (
											<p className="error-msg">
												{errors.surveyDescription?.message}
											</p>
										)}

										<ApplyTextLabel>
											{' '}
											<span>
												{surveyFormDropDown.label.QUESTIONNAIRE_TYPE}
												<span className={classes.astrickClass}>*</span>
											</span>
										</ApplyTextLabel>
										<StyledFormControl sx={{ minWidth: 120 }}>
											<StyledSelectField
												data-testid="type-field"
												value={surveyType}
												placeholder={commonLabels.selectLabel}
												{...register('surveyType', {
													required: commonLabels.questionnaireType,
												})}
												onChange={(event) =>
													handleQuestionnaireType(
														event as React.ChangeEvent<HTMLInputElement>
													)
												}
												displayEmpty
												renderValue={() =>
													surveyType ? surveyType : commonLabels.selectLabel
												}
											>
												{configs &&
													configs?.type?.map((typeItem: string) => (
														<MenuItem value={typeItem} key={typeItem}>
															{typeItem}
														</MenuItem>
													))}
											</StyledSelectField>
										</StyledFormControl>

										{errors.surveyType && (
											<p className="error-msg">{errors.surveyType?.message}</p>
										)}
										<StyledTooltip
											title={commonLabels.completingQuestionnaireForTooltip}
											placement="right"
										>
											<ApplyTextLabel>
												{' '}
												<span>
													{surveyFormDropDown.label.COMPLETE_SERVEY_FOR}
													<span className={classes.astrickClass}>*</span>
												</span>
											</ApplyTextLabel>
										</StyledTooltip>
										<Box>
											<Button
												variant="outlined"
												id="questionnaireForButton"
												aria-controls={
													openDropdown ? 'questionnaireForMenu' : undefined
												}
												aria-haspopup="true"
												aria-expanded={openDropdown ? 'true' : undefined}
												onClick={handleClick}
												sx={{ textTransform: 'normal', fontWeight: 'normal' }}
												className={classes.quessionariesForBtn}
											>
												<ButtonLabel>
													{getValues().questionnaireFor
														? getValues().questionnaireFor
														: commonLabels.selectLabel}
												</ButtonLabel>
											</Button>
											<Menu
												id="questionnaireForMenu"
												anchorEl={dropdownElement}
												open={openDropdown}
												onClose={handleClose}
												className={classes.quessionariesForMenu}
												MenuListProps={{
													'aria-labelledby': 'questionnaireForButton',
												}}
											>
												<MenuItem sx={{ paddingBottom: '0' }}>
													<FormControl>
														<QuestionnaireForTextField
															data-testid="questionnairefor-field"
															value={getValues().questionnaireFor}
															{...register('questionnaireFor', {
																required: commonLabels.questionnaireForRequired,
															})}
														></QuestionnaireForTextField>
													</FormControl>
													<FormControl>
														<QuestionnaireForRadioGroup
															value={questionnaireForRadio}
															onChange={handleRadioButton}
														>
															<FormControlLabel
																{...register('questionnaireForRadio', {
																	onChange: (e) => {
																		handleRadioButton(e);
																	},
																})}
																sx={{ fontWeight: 'normal' }}
																value={BASIC_CONSTANT.SELF}
																control={<Radio />}
																label={BASIC_CONSTANT.SELF}
															/>
															<FormControlLabel
																{...register('questionnaireForRadio', {
																	onChange: (e) => {
																		handleRadioButton(e);
																	},
																})}
																sx={{ fontWeight: 'normal' }}
																value={BASIC_CONSTANT.OTHERS}
																control={<Radio />}
																label={commonLabels.onBehalf}
															/>
														</QuestionnaireForRadioGroup>
													</FormControl>
												</MenuItem>
												<MenuItem sx={{ paddingTop: '0' }}>
													<FormGroup
														sx={{
															display: 'flex',
															direction: 'column',
															justifyContent: 'left',
															alignItems: 'left',
															paddingLeft: '42px',
														}}
													>
														<QuessionariesForLabel
															control={<Checkbox />}
															value={BASIC_CONSTANT.CONTINGENT}
															label={BASIC_CONSTANT.CONTINGENT}
															disabled={
																questionnaireForRadio !== BASIC_CONSTANT.OTHERS
															}
															checked={
																questionnaireForRadio === BASIC_CONSTANT.SELF
																	? false
																	: checkBoxValue.includes(
																			BASIC_CONSTANT.CONTINGENT
																		)
															}
															onChange={(event) =>
																handleValue(
																	event as React.ChangeEvent<HTMLInputElement>
																)
															}
														/>

														<QuessionariesForLabel
															control={<Checkbox />}
															value={BASIC_CONSTANT.DIRECT}
															label={BASIC_CONSTANT.DIRECT}
															disabled={
																questionnaireForRadio !== BASIC_CONSTANT.OTHERS
															}
															checked={
																questionnaireForRadio === BASIC_CONSTANT.SELF
																	? false
																	: checkBoxValue.includes(
																			BASIC_CONSTANT.DIRECT
																		)
															}
															onChange={(event) =>
																handleValue(
																	event as React.ChangeEvent<HTMLInputElement>
																)
															}
														/>
													</FormGroup>
												</MenuItem>
											</Menu>
										</Box>

										{errors.questionnaireFor && (
											<p className="error-msg">
												{errors.questionnaireFor?.message}
											</p>
										)}
										<StyledTooltip
											title={commonLabels.questionnaireOwingLocationTooltip}
											placement="right"
										>
											<ApplyTextLabel>
												<span>
													{surveyFormDropDown.label.QUESTIONNAIRE_CATEGORY}
													<span className={classes.astrickClass}>*</span>
												</span>
											</ApplyTextLabel>
										</StyledTooltip>
										<StyledFormControl sx={{ minWidth: 120 }}>
											<StyledSelectField
												data-testid="location-field"
												value={category}
												placeholder={commonLabels.selectLabel}
												{...register('surveyOwningLocation', {
													required: commonLabels.owningRequired,
												})}
												onChange={(event) =>
													handleCategory(
														event as React.ChangeEvent<HTMLInputElement>
													)
												}
												displayEmpty
												renderValue={() => category || commonLabels.selectLabel}
											>
												{configs &&
													configs?.location?.map((locationItem: string) => (
														<MenuItem value={locationItem} key={locationItem}>
															{locationItem}
														</MenuItem>
													))}
											</StyledSelectField>
										</StyledFormControl>
										{errors.surveyOwningLocation && (
											<p className="error-msg">
												{errors.surveyOwningLocation?.message}
											</p>
										)}
										<StyledTooltip
											title={commonLabels.questionnaireStartDateTooltip}
											placement="right"
										>
											<ApplyTextLabel>
												{' '}
												<span>
													{surveyFormDropDown.label.QUESTIONNAIRE_START_DATE}
													<span className={classes.astrickClass}>*</span>
												</span>
											</ApplyTextLabel>
										</StyledTooltip>
										<StyledFormControl data-testid="startdate-field">
											<Controller
												control={control}
												name="surveyStartDate"
												render={({
													// eslint-disable-next-line @typescript-eslint/no-unused-vars
													field: { ref, onBlur, name, onChange, ...field },
												}) => (
													<DatePicker
														minDate={
															new Date(
																new Date().getTime() +
																	Numbers.twentyFour *
																		Numbers.sixty *
																		Numbers.sixty *
																		Numbers.thousand
															)
														}
														{...field}
														{...register('surveyStartDate', {
															valueAsDate: true,

															required: {
																value: true,
																message: commonLabels.startDateReq,
															},
														})}
														format="dd/MM/yyyy"
														value={surveyStartDate}
														onChange={(newValue) => {
															setSurveyStartDate(newValue);
															setValue(
																'surveyStartDate',
																newValue || surveyStartDate
															);
															clearErrors('surveyStartDate');
														}}
														maxDate={surveyDueDate}
														sx={{
															'&.MuiFormControl-root-MuiTextField-root': {
																width: '322px',
																border: '4px',
																marginTop: '2px',
															},
															'.MuiInputBase-input': {
																paddingTop: '10px ',
																paddingBottom: '10px',
																height: '1.2em',
															},
															'.MuiOutlinedInput-root': {
																borderRadius: '8px',
															},
														}}
														slotProps={{
															field: {
																readOnly: true,
															},
														}}
													/>
												)}
											/>
										</StyledFormControl>

										{errors.surveyStartDate && (
											<p className="error-msg">
												{errors.surveyStartDate?.message}
											</p>
										)}
										<StyledTooltip
											title={commonLabels.questionnaireDueDateTooltip}
											placement="right"
										>
											<ApplyTextLabel>
												<span>
													{surveyFormDropDown.label.QUESTIONNAIRE_DUE_DATE}
													<span className={classes.astrickClass}>*</span>
												</span>
											</ApplyTextLabel>
										</StyledTooltip>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',

												'&.MuiFormGroup-root': {
													gap: '90px',
												},
											}}
										>
											<RadioGroup
												data-testid="dateradio-field"
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												value={value}
												sx={{ '&.MuiFormGroup-root': { gap: '90px' } }}
												onChange={handleRadioChange}
											>
												<FormControlLabel
													{...register('value', {
														required: {
															value: true,
															message: commonLabels.dueDateReq,
														},
													})}
													value={BASIC_CONSTANT.DUE_DATE}
													control={<Radio />}
													label={
														<span className={classes.labelDate}>
															{commonLabels.date}
														</span>
													}
												/>

												<FormControlLabel
													{...register('value', {
														required: {
															value: true,
															message: commonLabels.dueDateReq,
														},
													})}
													value={BASIC_CONSTANT.DURATION}
													control={<Radio />}
													label={
														<span className={classes.labelDate}>
															{commonLabels.duration}
														</span>
													}
												/>
											</RadioGroup>
										</Box>
										{errors.value && (
											<p className="error-msg">{errors.value?.message}</p>
										)}
										{getQuestionnaireType()}
									</form>
								</LocalizationProvider>
							</CardContent>
						</Card>
					</StyleFormContainer>
				</StyledQuestionnaireAddContainer>
				<StyledButtonsContainer>
					<QuestionFooter
						handleNext={handleSubmit(onSubmit)}
						handleSaveDraft={handleSubmit(handleSaveDraft)}
						buttonEnable={false}
					></QuestionFooter>
				</StyledButtonsContainer>
				<GenericModalPopup
					openProp={open}
					msgBody={commonLabels.saveAsDraftText}
					mainMsg=""
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleClosePopup}
					leftBtnHandler={handleClosePopup}
				/>
				<GenericModalPopup
					openProp={openErrorPopup}
					msgBody={errMsgInSaveAsDraft?.errorMessage || commonLabels.errorMsg}
					mainMsg=""
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleCloseErrorPopup}
					leftBtnHandler={handleCloseErrorPopup}
				/>
			</StyledWholeQuestionnaireContainer>
		</>
	);
};
export default CreateSurveyDetails;
