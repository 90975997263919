import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, FormControl, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ISurveyFormsQuestion, SurveyOptions } from '../../types/SurveyProps';
import {
	MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { Numbers } from '../../utils/Enum';
import { commonLabels } from '../../utils/CommonLabels';
import { useAppSelector } from '../../app/Hooks';
import {
	selectSurveyFormsQuestion,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { RootState } from '../../app/Store';
import { getCheckBoxState } from '../../redux/QuestionnaireSurveySlice';
import StyledTooltip from '../StyledTooltip';
import { isIdenticalMultiOptionName } from '../../utils/Helpers';
import { isDuplicateQuestion } from '../../utils/CommonMethods';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import QuestionActions from './QuestionActions';
import QuestionInstruction from './QuestionInstruction';
import ConfigureOptions from './ConfigureOptions';
import InputCharacterRemaining from './InputCharacterRemaining';
import {
	UseStyles,
	RemoveOptionBox,
	QuestionBox,
	QuestionTitle,
	QuestionType,
	QuestionOptions,
	QuestionNumber,
	OptionAddButton,
} from './common/QuestionStyles';

const OptionRadio = styled(Radio)({
	paddingBottom: '0',
	paddingRight: '3px',
	paddingLeft: '0',
});

const QuestionMultiOption: React.FC<{
	item: ISurveyFormsQuestion;
	index: number;
	onDelete: (i: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion, index: number) => void;
}> = ({ item, index, onDelete, onQueClone, onQueSave }) => {
	const classes = UseStyles();
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const surveyFormsQuestion = useSelector(selectSurveyFormsQuestion);
	const questionFormRef = useRef<HTMLInputElement | null>(null);
	const form = useForm<ISurveyFormsQuestion>();
	const {
		register,
		handleSubmit,
		control,
		formState,
		watch,
		clearErrors,
		setError,
		setValue,
	} = form;
	const { errors } = formState;
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'options',
	});

	const [data, setData] = useState<ISurveyFormsQuestion>({
		questionId: '',
		questionType: QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION,
		options: [],
		question: '',
		instructions: '',
		order: 0,
		questionIsValid: false,
		questionButtonLabel: commonLabels.save,
	});

	const [isEditable, setIsEditable] = useState(
		item.questionButtonLabel === commonLabels.save ? true : false
	);
	const [label, setLabel] = useState(item.questionButtonLabel);
	const [isPopUpOpen, setIsPopupOpen] = useState(false);
	const [isQuestionEmpty, setIsQuestionEmpty] = useState(false);
	const [isIdenticalOptionName, setIsIdenticalOptionName] = useState(false);
	const [instructionError, setInstructionError] = useState(false);
	const handleDeleteQue = () => {
		onDelete(item?.questionId);
	};
	const handleQueClone = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.options = controlledFields.map((s) => {
			return { options: s?.options, condition: s?.condition };
		});
		onQueClone(newData);
	};

	const questionId = item?.questionId;

	const checkboxState = useSelector((state: RootState) =>
		getCheckBoxState(questionId)(state)
	);

	const [charsCountForName, setCharsCountForName] = React.useState(0);
	const remainingCountForQuestionTitle: number =
		QUECONSTANT.MAX_LENGTH_QUESTION_TITLE - charsCountForName;

	const handleSaveQue = () => {
		if (questionFormRef?.current) {
			questionFormRef.current.click();
		}
	};

	const handleAddOption = (options: string, condition: string) => {
		if (controlledFields.length < Numbers.four) {
			append({ options: options, condition: condition });
		}
	};

	const handleConfirmPopup = () => {
		setIsPopupOpen(false);
		return;
	};

	const watchFieldArray = watch('options');
	const controlledFields = fields.map((field, i) => {
		if (watchFieldArray)
			return {
				...field,
				...watchFieldArray[i],
			};
	});

	const handleRemoveOption = (optionId: number | undefined) => {
		if (isEditable) {
			remove(optionId);
		}
	};

	const updateFormData = (
		prevData: ISurveyFormsQuestion,
		key: string,
		value: string
	) => ({
		...prevData,
		[key]: value,
	});

	const selectCurrentQuestion = () =>
		surveyFormsQuestion.find((question) => question.questionId === questionId)
			?.question;

	const currentQuestion = selectCurrentQuestion();

	useEffect(() => {
		setData((prevData) => ({
			...prevData,
			question: currentQuestion,
		}));
	}, [currentQuestion]);

	const handleFormChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		key: string
	) => {
		setData((prevData) => updateFormData(prevData, key, event.target.value));
		setCharsCountForName(event.target.value.length);
	};

	useEffect(() => {
		setData(item);
		item.question && setCharsCountForName(item.question.length);
		for (const iterator of item.options) {
			handleAddOption(iterator.options, iterator.condition);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	/**This effect run to update & sync the store arrays with watch arrays of option */
	useEffect(() => {
		if (surveyFormsQuestion.length > 0 && item && item.options?.length > 0) {
			const updatedStateOptions =
				surveyFormsQuestion.find(
					(question) => question.questionId === item.questionId
				)?.options || item.options;
			setValue('options', updatedStateOptions as SurveyOptions[]);
		}
	}, [surveyFormsQuestion, item, setValue]);

	const onSubmit = (formData: ISurveyFormsQuestion) => {
		// Below parameter are not part of form, need to add in submit object
		formData.questionType = QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION;
		formData.isNew = item.isNew;
		formData.questionId = item.questionId;
		formData.order = item.order;
		formData.instructions = data.instructions;
		formData.question = data.question;

		const isDuplicate = isDuplicateQuestion(formData, surveyFormsQuestion);

		if (isDuplicate) {
			setIsPopupOpen(true);
			return;
		}

		const questionValue = data?.question;
		const CheckBoxState = checkboxState;
		const Instructions = data.instructions;
		let questionValid = true;
		if (CheckBoxState && (!Instructions || Instructions.trim() === '')) {
			questionValid = false;
			alert('Please add instruction');
		}
		if (instructionError) {
			return;
		}
		if (controlledFields.length < Numbers.two) {
			questionValid = false;
			alert(commonLabels.minOption);
		}
		/**this logic will validate same Multi select option name */
		if (isIdenticalMultiOptionName(controlledFields as SurveyOptions[])) {
			questionValid = false;
			setIsIdenticalOptionName(true);
			return;
		}
		if (questionValue && questionValue.trim() !== '') {
			setIsQuestionEmpty(false);
		} else {
			setIsQuestionEmpty(true);
			questionValid = false;
		}
		if (questionValid) {
			setIsEditable(!isEditable);
			const buttonLabel = isEditable ? commonLabels.edit : commonLabels.save;
			setLabel(buttonLabel);
			formData.questionButtonLabel = buttonLabel;
			formData.questionIsValid = true;
		}
		formData.questionIsValid = questionValid;
		onQueSave(formData, index);
		if (item.isNew) {
			item.isNew = false;
		}
	};

	const updateInstructions = (instructions: string) => {
		setData((prevData) =>
			updateFormData(prevData, 'instructions', instructions)
		);
	};
	const handleInstructionError = (error: boolean) => {
		setInstructionError(error);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} data-testid="question-multi-choice">
			<QuestionBox>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<Box>
							<QuestionType>
								<Box>{commonLabels.multiOptionQue} </Box>
								<Box>
									<InputCharacterRemaining
										remainingCharacterCount={remainingCountForQuestionTitle}
									/>
								</Box>
							</QuestionType>
							<QuestionTitle>
								<QuestionNumber>{index + 1}.</QuestionNumber>
								<StyledTooltip
									title={commonLabels.questionFieldTooltip}
									placement="top"
								>
									<TextField
										data-testid="question-id"
										className={classes.titleInput}
										InputProps={{ classes: { root: classes.formControlOuter } }}
										autoFocus
										margin="dense"
										id="name"
										type="text"
										fullWidth
										disabled={!isEditable}
										value={data.question}
										onKeyPress={(event) => {
											const inputValue = (event.target as HTMLInputElement)
												.value;
											if (event.key === ' ' && !inputValue) {
												event.preventDefault();
											}
										}}
										error={isQuestionEmpty}
										helperText={isQuestionEmpty ? commonLabels.queRequired : ''}
										inputProps={{
											maxLength: QUECONSTANT.MAX_LENGTH_QUESTION_TITLE,
										}}
										variant="standard"
										{...register('question', {
											onChange(event) {
												handleFormChange(event, 'question');
												const inputValue = event.target.value;
												if (inputValue.length === 0) {
													clearErrors('question');
												} else if (
													!inputValue.match(
														VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS
													)
												) {
													setError('question', {
														type: 'manual',
														message:
															commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
													});
												} else {
													clearErrors('question');
												}
											},
											maxLength: {
												value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
												message: commonLabels.queMaxLength,
											},
											pattern: {
												value: VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS,
												message:
													commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
											},
										})}
									/>
								</StyledTooltip>
								{errors.question && (
									<p className={classes.errorMsg}>
										<span className="error-msg">
											{errors.question?.message}
										</span>
									</p>
								)}
							</QuestionTitle>
							<QuestionOptions>
								{controlledFields?.map((option, i) => (
									<Box key={option?.id}>
										<FormControl
											className={classes.formControlMultiOuter}
											component="fieldset"
										>
											<OptionRadio disabled />
											<Box className={classes.formControlInputBox}>
												<TextField
													className={classes.formControlInputCustom}
													margin="dense"
													id="name"
													type="text"
													fullWidth
													disabled={!isEditable}
													variant="standard"
													onKeyPress={(event) => {
														const inputValue = (
															event.target as HTMLInputElement
														).value;
														if (event.key === ' ' && !inputValue) {
															event.preventDefault();
														}
													}}
													inputProps={{
														maxLength:
															QUECONSTANT.MAX_LENGTH_QUESTION_OPTIONS_TEXT_LENGTH,
													}}
													{...register(`options.${i}.options` as const, {
														required: commonLabels.optionRequired,
														maxLength: {
															value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
															message: commonLabels.queMaxLength,
														},
														pattern: {
															value:
																VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS,
															message:
																commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
														},
														onChange(event) {
															const inputValue = event.target.value;
															setIsIdenticalOptionName(false);
															if (inputValue.length === 0) {
																clearErrors(`options.${i}.options`);
															} else if (
																!inputValue.match(
																	VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS
																)
															) {
																setError(`options.${i}.options`, {
																	type: 'manual',
																	message:
																		commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
																});
															} else {
																clearErrors(`options.${i}.options`);
															}
														},
													})}
												/>
												{isEditable && (
													<RemoveOptionBox
														onClick={() => handleRemoveOption(i)}
													>
														<CloseRoundedIcon></CloseRoundedIcon>
													</RemoveOptionBox>
												)}
												{errors.options && errors.options[i]?.options && (
													<p className={classes.errorOptionMsg}>
														{errors.options &&
															errors.options[i]?.options?.message && (
																<span className="error-msg">
																	{errors.options[i]?.options?.message}
																</span>
															)}
													</p>
												)}
											</Box>
											{
												<ConfigureOptions
													option={option}
													isEditable={isEditable}
													index={index}
													questions={
														surveyQuestionnaireInfo.surveyFormsQuestion
													}
													classN={classes.formControlSelectCustom}
													register={register}
													i={i}
												/>
											}
										</FormControl>
									</Box>
								))}
								{isIdenticalOptionName ? (
									<p className={classes.errorOptionMsg}>
										<span className="error-msg">
											{commonLabels.identicalOptionsValidation}
										</span>
									</p>
								) : (
									''
								)}
							</QuestionOptions>
							<OptionAddButton
								variant="outlined"
								onClick={() => handleAddOption('option', '')}
								disabled={!isEditable}
							>
								{commonLabels.addOption}
							</OptionAddButton>
							<Box>
								<QuestionInstruction
									instructions={data.instructions}
									handleError={handleInstructionError}
									onUpdateInstructions={(instructions) =>
										updateInstructions(instructions)
									}
									readonly={!isEditable}
									questionId={item.questionId}
								></QuestionInstruction>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box>
							<QuestionActions
								onCloneQue={handleQueClone}
								item={item}
								onSaveQue={handleSaveQue}
								onOpenDelete={handleDeleteQue}
								label={label}
								index={index}
							/>
						</Box>
					</Grid>
				</Grid>
				<input
					data-testid="submit-multi-choice"
					ref={questionFormRef}
					type="submit"
					className={classes.submitHide}
				/>
				<GenericConfirmationPopup
					open={isPopUpOpen}
					msgBody={commonLabels.duplicateQuestionMessage}
					title={commonLabels.duplicateQuestionTitle}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleConfirmPopup}
					leftBtnHandler={handleConfirmPopup}
				/>
			</QuestionBox>
		</form>
	);
};
export default QuestionMultiOption;
