import axiosIntercept from '../app/authentication/axiosIntercept';
import {
	IGroupDataReq,
	IRegionGroupReq,
	IListViewReq,
	ResultsKeyMetricesRequest,
	IDownloadReq,
	ISUMMITReportRequestPayload,
} from '../types/QuestionnaireResultsType';
import { Numbers } from '../utils/Enum';

export const QuestionnaireResultsData = async (requestPayload: {
	questionnaireId: string;
	action?: string;
}) => {
	return axiosIntercept.get(
		`questionnaire/${requestPayload.questionnaireId}/getResults`
	);
};

export const DownloadResults = async (id: string) => {
	return axiosIntercept.post(`questionnaire/${id}/downloadresult`, null, {
		responseType: 'blob',
	});
};

export const SummitReportRequest = async (
	questionnaireId: string,
	requestPayload: ISUMMITReportRequestPayload,
	groupId?: string[]
) => {
	const groupText = `/${groupId?.toString()}`;
	const URL = `questionnaireresult/download/edgeform/questionnaire/${questionnaireId}/group${groupId && groupId?.length > Numbers.zero ? groupText : ''}`;
	return axiosIntercept.post(URL, requestPayload);
};

export const ReportDownload = (
	questionnaireId: string,
	downloadReqPayload: IDownloadReq
) => {
	const url = `QuestionnaireResult/download/questionnaire/${questionnaireId}`;
	return axiosIntercept.post(`${url}`, downloadReqPayload);
};

export const FetchListViewResults = (
	questionnaireId: string,
	listRequestPayload: IListViewReq
) => {
	const url = `questionnaireresult/list/`;
	return axiosIntercept.post(`${url}${questionnaireId}`, listRequestPayload);
};

export const GetParameterList = async () => {
	return axiosIntercept.get('configs?category=result_matrics&name=parameters');
};

export const GetGroupData = async (requestPayload: {
	questionnaireId: string;
	actionPayload: IGroupDataReq;
}) => {
	return axiosIntercept.post(
		`QuestionnaireResult/group/${requestPayload.questionnaireId}`,
		requestPayload?.actionPayload
	);
};

export const GetResponseGraphData = async (requestPayload: {
	questionnaireId: string;
	includeFilter: string;
	excludeFilter: string;
}) => {
	return axiosIntercept.post(
		`questionnaireresult/questions/${requestPayload.questionnaireId}`,
		{
			includeFilter: requestPayload.includeFilter,
			excludeFilter: requestPayload.excludeFilter,
		}
	);
};

export const GetResultKeyMetrices = async (requestPayload: {
	questionnaireId: string;
	actionPayload: ResultsKeyMetricesRequest;
}) => {
	return axiosIntercept.post(
		`QuestionnaireResult/keyMetrics/${requestPayload?.questionnaireId}`,
		requestPayload?.actionPayload
	);
};

export const GetRegionalData = async (regionGroupReq: IRegionGroupReq) => {
	const questionnaireId = regionGroupReq.questionnaireId;
	delete regionGroupReq.questionnaireId;
	return axiosIntercept.post(
		`QuestionnaireResult/region/${questionnaireId}`,
		regionGroupReq
	);
};

export default QuestionnaireResultsData;
