import {
	EndUserListingRequest,
	IAppliedFilterArray,
	IDateFilter,
	IEndUserQuestionnarieListingDataItem,
	IRolePermissions,
	ISurveyListingDataItem,
	ISurveyListingRequest,
} from '../types/CommonTypes';
import {
	INotificationConfig,
	IQuestionnaireDataRequest,
	ISurveyFormsDetails,
	ISurveyFormsQuestion,
	ISurveyFormsQuestionnaire,
	SurveyOptions,
} from '../types/SurveyProps';
import {
	ROLES,
	ROLE_AD_GROUPS,
	specialCharacterForAnswers,
} from '../constants/StepConstants';
import { getAuthDetails } from '../app/authentication/authToken';
import { DataItemProps } from '../types/ResultsType';
import { Numbers, QUE_JOURNEY_STEPS } from './Enum';

import { manageAudienceFilters } from './Filter';
import { initialFilterLabelJson, initialFilterValue } from './SurveyAudience';
import { commonLabels } from './CommonLabels';

export const reorder = <T>(
	list: T[],
	startIndex: number,
	endIndex: number
): T[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const transformUserWWIDSearchData = (
	userWWIDSearchData: { result: string }[]
) => {
	return userWWIDSearchData.map((item) => {
		return item.result;
	});
};

export const dynamicId = (questions: ISurveyFormsQuestion[]) => {
	if (questions.length === 0) {
		return Numbers.oneLakhOne.toString();
	}
	const maxId = Math.max(...questions.map((q) => parseInt(q.questionId)));
	return (maxId + Numbers.one).toString();
};

export const isDataAvailable = (
	chartSummary: DataItemProps[],
	threshold = Numbers.zero
) => {
	return chartSummary.some((summary) => summary.value > threshold);
};

export const getQuestionnairePayload = (data: ISurveyFormsQuestionnaire) => {
	let questionJSONString = '';
	if (data?.surveyFormsQuestion?.length > 0) {
		const questions = data.surveyFormsQuestion.map((question) => ({
			questionId: question.questionId,
			questionType: question.questionType,
			options: question.options,
			question: question.question,
			instructions: question.instructions,
			condition: question.condition,
			order: question.order,
			questionButtonLabel: question.questionButtonLabel,
			fileName: question.fileName ? question.fileName : undefined,
		}));
		questionJSONString = JSON.stringify(questions);
	}
	const notificationConfigTempForInitialEmail = {
		subject: data?.notificationConfig?.initialEmailSubject,
		body: data?.notificationConfig?.initialEmailBody,
	};
	const notificationConfigTempForReminderEmail = {
		subject: data?.notificationConfig?.reminderEmailSubject,
		body: data?.notificationConfig?.reminderEmailBody,
	};
	const notificationConfigTempForOverdueEmail = {
		subject: data?.notificationConfig?.overdueEmailSubject,
		body: data?.notificationConfig?.overdueEmailBody,
	};

	const notificationConfigTempForReminderEmailFrequency = {
		data: data?.notificationConfig?.reminderEmailFrequency,
		current: 1,
	};

	const notificationConfigTempForOverdueEmailFrequency = {
		data: data?.notificationConfig?.overdueEmailFrequency,
		current: 1,
	};

	return {
		basicDetails: {
			id: data?.surveyFormsDetails?.id ? data.surveyFormsDetails.id : null,
			questionnaireCode: data?.surveyFormsDetails?.questionnaireCode
				? data?.surveyFormsDetails?.questionnaireCode
				: '',
			questionnaireName: data?.surveyFormsDetails?.surveyName || '',
			questionnaireDescription:
				data?.surveyFormsDetails?.surveyDescription || '',
			status: data?.surveyFormsDetails?.status,
			location: data?.surveyFormsDetails?.surveyOwningLocation || '',
			questionnaireType: data?.surveyFormsDetails?.surveyType || '',
			targetAudience: data?.surveyFormsDetails?.questionnaireFor || '',
			startDate:
				data?.surveyFormsDetails?.surveyStartDate?.toLocaleDateString('en-CA'),
			dueDate:
				data?.surveyFormsDetails?.surveyDueDate?.toLocaleDateString('en-CA'),
			duration: data?.surveyFormsDetails?.surveyDuration || 0,
			isFromNotification: data?.surveyFormsDetails?.isFromNotification,
		},
		audience: {
			audienceFilters: data?.includeClause,
			exclusionFilters: data?.excludeClause,
			audienceCollection: JSON.stringify(data?.surveyFormsAudience) || [],
			filterLabelJson: JSON.stringify({
				includeFilters: data?.surveyIncludedAudienceFilter,
				excludeFilters: data?.surveyExcludedAudienceFilter,
			}),
		},
		qAndA: {
			questionnaireInstructions: data?.instruction,
			questionJSONString: questionJSONString,
		},
		notificationConfig: {
			initialEmailFormat: JSON.stringify(notificationConfigTempForInitialEmail),
			reminderEmailFormat: JSON.stringify(
				notificationConfigTempForReminderEmail
			),
			overdueEmailFormat: JSON.stringify(notificationConfigTempForOverdueEmail),
			reminderEmailFrequency:
				notificationConfigTempForReminderEmailFrequency.data?.length > 0
					? JSON.stringify(notificationConfigTempForReminderEmailFrequency)
					: '',
			overdueEmailFrequency:
				notificationConfigTempForOverdueEmailFrequency.data?.length > 0
					? JSON.stringify(notificationConfigTempForOverdueEmailFrequency)
					: '',
		},
		currentStep: data?.currentStep,
		createdBy: '',
		updatedBy: '',
		idToken: data?.idToken,
	} as unknown as IQuestionnaireDataRequest;
};

export const getFilterLabelById = (id: string) => {
	switch (id) {
		case 'people_leader':
			return manageAudienceFilters.people_leader;
		case 'worker_type':
			return manageAudienceFilters.worker_type;
		case 'work_region':
			return manageAudienceFilters.work_region;
		case 'work_country':
			return manageAudienceFilters.work_country;
		case 'sector':
			return manageAudienceFilters.sector;
		case 'mrc_code':
			return manageAudienceFilters.mrc_code;
		case 'legal_entity':
			return manageAudienceFilters.legal_entity;
		case 'job_function':
			return manageAudienceFilters.job_function;
		case 'job_subfunction':
			return manageAudienceFilters.job_subfunction;
		case 'job_group':
			return manageAudienceFilters.job_group;
		case 'employee_function':
			return manageAudienceFilters.employee_function;
		case 'manager_wwid':
			return manageAudienceFilters.manager_wwid;
		case 'wwid':
			return manageAudienceFilters.wwid;
		default:
			return '';
	}
};

export const getKey = (surveyAudienceFilterValues: {
	[key: string]: string[];
}) => {
	return Object.keys(surveyAudienceFilterValues);
};

export const getQuestionnaireByIdResponse = (
	data: IQuestionnaireDataRequest
) => {
	const surveyFormsDetails: ISurveyFormsDetails = {
		id: data?.basicDetails?.id,
		questionnaireCode: data?.basicDetails?.questionnaireCode,
		surveyName: data?.basicDetails?.questionnaireName,
		surveyType: data?.basicDetails?.questionnaireType,
		questionnaireFor: data?.basicDetails?.targetAudience,
		surveyOwningLocation: data?.basicDetails?.location,
		surveyStartDate: new Date(data?.basicDetails?.startDate),
		surveyDueDate: data?.basicDetails?.dueDate
			? new Date(data?.basicDetails?.dueDate)
			: null,
		surveyDescription: data?.basicDetails?.questionnaireDescription,
		surveyDuration: data?.basicDetails?.duration,
		status: data?.basicDetails?.status,
		isFromNotification: !!(
			data?.basicDetails?.status === 'Published' ||
			data?.basicDetails?.status === 'Active'
		),
	};
	let surveyFormsQuestion: ISurveyFormsQuestion[] = [];
	if (data?.qAndA?.questionJSONString) {
		const parseData = JSON.parse(data?.qAndA?.questionJSONString);
		surveyFormsQuestion = parseData.map((question: ISurveyFormsQuestion) => {
			return {
				questionId: question.questionId,
				questionType: question.questionType,
				options: question.options,
				question: question.question,
				condition: question.condition,
				instructions: question.instructions,
				isNew: false,
				order: question.order,
				fileName: getFileName(question),
				questionButtonLabel: getButtonLabel(question),
			};
		});
	}
	const filterData = data?.audience?.filterLabelJson
		? JSON.parse(data?.audience?.filterLabelJson || '')
		: null;
	const surveyFormsAudience = '';
	const notificationConfig: INotificationConfig = {
		initialEmailSubject: getEmailSubjectBody(
			data?.notificationConfig?.initialEmailFormat
		)?.subject,
		initialEmailBody: getEmailSubjectBody(
			data?.notificationConfig?.initialEmailFormat
		)?.body,
		reminderEmailSubject: getEmailSubjectBody(
			data?.notificationConfig?.reminderEmailFormat
		)?.subject,
		reminderEmailBody: getEmailSubjectBody(
			data?.notificationConfig?.reminderEmailFormat
		)?.body,
		reminderEmailFrequency: getFrequencyArray(
			data?.notificationConfig?.reminderEmailFrequency
		),
		overdueEmailSubject: getEmailSubjectBody(
			data?.notificationConfig?.overdueEmailFormat
		)?.subject,
		overdueEmailFrequency: getFrequencyArray(
			data?.notificationConfig?.overdueEmailFrequency
		),
		overdueEmailBody: getEmailSubjectBody(
			data?.notificationConfig?.overdueEmailFormat
		)?.body,
	};
	const qAndAInstruction = data?.qAndA?.questionnaireInstructions || '';
	return {
		includeClause: getQuestionnaireByIdSelectedFilter(filterData, data)
			.includeClause,
		excludeClause: getQuestionnaireByIdSelectedFilter(filterData, data)
			.excludeClause,
		filterLabelJson: data?.audience?.filterLabelJson || initialFilterLabelJson,
		currentStep: data.currentStep
			? data.currentStep
			: QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS,
		surveyFormsDetails: surveyFormsDetails,
		surveyFormsAudience: surveyFormsAudience,
		surveyFormsQuestion: surveyFormsQuestion,
		notificationConfig: notificationConfig,
		surveyIncludedAudienceFilter:
			filterData?.includeFilters || JSON.stringify({ ...initialFilterValue }),
		surveyExcludedAudienceFilter:
			filterData?.excludeFilters || JSON.stringify({ ...initialFilterValue }),
		selectedFilterRequestPayload: getQuestionnaireByIdSelectedFilter(
			filterData,
			data
		),
		shouldTriggerAPI: false,
		instruction: qAndAInstruction,
		userEmailId: '',
	};
};
const getQuestionnaireByIdSelectedFilter = (
	filterData: IAppliedFilterArray,
	data: IQuestionnaireDataRequest
) => {
	return {
		includeFilters: filterData?.includeFilters || initialFilterValue,
		excludeFilters: filterData?.excludeFilters || initialFilterValue,
		includeClause: data?.audience?.audienceFilters || '',
		excludeClause: data?.audience?.exclusionFilters || '',
		filterLabelJson: data?.audience?.filterLabelJson || initialFilterLabelJson,
		pageNumber: Numbers.one,
		pageSize: Numbers.ten,
	} as IAppliedFilterArray;
};

const getEmailSubjectBody = (email: string | string[]) => {
	if (typeof email === 'string') {
		const parseEmail = JSON.parse(email);
		return {
			subject: parseEmail.subject,
			body: parseEmail.body,
		};
	}
};
const tryParseJSON = (frequencyString: string) => {
	try {
		const stringObject = JSON.parse(frequencyString);
		if (stringObject && typeof stringObject === 'object') {
			return stringObject;
		}
	} catch (error) {
		return null;
	}
};
const getFrequencyArray = (frequencyString: string | string[]) => {
	if (typeof frequencyString === 'string' && frequencyString !== '') {
		if (tryParseJSON(frequencyString).data.length > 0) {
			return tryParseJSON(frequencyString).data;
		}
	} else {
		return '';
	}
	return frequencyString;
};
// This function will test the string against provided RegExp and return a boolean
export const isValidQuestionnaireDesc = (questionnaireName: string) => {
	const namePattern =
		/^(?!.*[-_&]{2})[a-zA-Z0-9][a-zA-Z0-9 &\-_]*[a-zA-Z0-9 \-_&]$/;
	return namePattern.test(questionnaireName);
};
/**This helper will validate common logic and return boolean if there is same option name for Multi Choice Options */
export const isIdenticalMultiOptionName = (
	controlledFields: SurveyOptions[]
) => {
	return controlledFields.some((firstLevelitem) => {
		return controlledFields.some((secondLevelItem) => {
			/**below will compare same option name with diff parent id */
			return (
				secondLevelItem.options.toLowerCase().trim() ===
					firstLevelitem.options.toLowerCase().trim() &&
				firstLevelitem.id !== secondLevelItem.id
			);
		});
	});
};

const getFileName = (question: ISurveyFormsQuestion) => {
	return question.fileName ? question.fileName : undefined;
};

const getButtonLabel = (question: ISurveyFormsQuestion) => {
	return question.questionButtonLabel
		? question.questionButtonLabel
		: commonLabels.edit;
};

const getCurrentEnv = () => {
	return `${process.env.REACT_APP_ENVIRONMENT}`.toUpperCase();
};

function parseJwt(token: string) {
	const base64Url = token.split('.')[Numbers.one];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const decodedJWT = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return (
					'%' +
					('00' + c.charCodeAt(Numbers.zero).toString(Numbers.sixteen)).slice(
						Numbers.minusTwo
					)
				);
			})
			.join('')
	);
	return JSON.parse(decodedJWT);
}

export const processJWT = (idToken: string) => {
	const parsedToken = parseJwt(idToken);
	return {
		userEmailId: parsedToken?.preferred_username,
		userName: parsedToken?.name,
		roles: parsedToken?.roles,
	};
};

export const isCurrentUser = (emailId = '') => {
	const userEmailId = getAuthDetails()?.userEmailId;
	return userEmailId.toLocaleLowerCase() === emailId.toLocaleLowerCase();
};

export const checkRolePermissions = () => {
	const roles: string[] = getAuthDetails()?.roles ?? [];
	const env = getCurrentEnv();
	const AD_groups = ROLE_AD_GROUPS(env);
	const role_permissions: IRolePermissions = {
		isLMSAdmin: false,
		isAdmin: false,
		isEndUser: true,
		isCWEndUser: false,
		isCWManger: false,
	};
	for (const [key, value] of Object.entries(AD_groups)) {
		if (roles?.includes(value)) {
			switch (key) {
				case `${ROLES.STEP_ADMIN}`:
					role_permissions.isAdmin = true;
					break;
				case `${ROLES.LMS_ADMIN}`:
					role_permissions.isLMSAdmin = true;
					break;
				case `${ROLES.CW_USER}`:
					role_permissions.isCWEndUser = true;
					break;
				case `${ROLES.CW_MANAGER}`:
					role_permissions.isCWManger = true;
					break;
				default:
					role_permissions.isEndUser = true;
			}
		}
	}
	return role_permissions;
};

export const filterSurveyData = (
	surveyList: ISurveyListingDataItem[],
	surveyReqData: ISurveyListingRequest,
	filteredDate?: IDateFilter
) => {
	let surveyFilteredData = surveyList;
	if (surveyReqData?.location) {
		const checkValue: string = surveyReqData?.location?.trimEnd().toLowerCase();
		surveyFilteredData = surveyFilteredData?.filter((item) => {
			return item?.location?.toLowerCase().startsWith(checkValue);
		});
	}

	if (surveyReqData?.questionnaireName) {
		const checkValue = surveyReqData?.questionnaireName
			?.trimEnd()
			.toLowerCase();
		surveyFilteredData = surveyFilteredData.filter((item) => {
			return (
				item?.questionnaireName?.toLowerCase().includes(checkValue) ||
				item?.questionnaireId?.toLowerCase().includes(checkValue)
			);
		});
	}

	if (surveyReqData?.questionnaireAuthor) {
		const checkValue = surveyReqData?.questionnaireAuthor
			?.trimEnd()
			.toLowerCase();
		surveyFilteredData = surveyFilteredData.filter((item) => {
			return item?.questionnaireAuthor.toLowerCase().includes(checkValue);
		});
	}

	if (surveyReqData?.status) {
		const checkValue: string = surveyReqData?.status.trimEnd().toLowerCase();
		surveyFilteredData = surveyFilteredData.filter((item) => {
			return item?.status.toLowerCase().includes(checkValue);
		});
	}
	if (
		filteredDate &&
		filteredDate.startDate !== '' &&
		filteredDate.endDate !== ''
	) {
		// apply start and end date filter
		surveyFilteredData = surveyFilteredData.filter((item) => {
			const currStartDate = new Date(item?.startDate).getTime();
			const initialDate = new Date(filteredDate.startDate).getTime();
			const endDate = new Date(filteredDate.endDate).getTime();
			return (
				currStartDate >= initialDate &&
				currStartDate - Numbers.TIME_ONE_DAY_IN_MILLI_SECS <= endDate
			);
		});
	}

	if (
		filteredDate &&
		filteredDate.createdAtStartDate !== '' &&
		filteredDate.createdAtEndDate !== ''
	) {
		// apply created start and end date filter
		surveyFilteredData = surveyFilteredData.filter((item) => {
			const currCreatedAtDate = new Date(item?.createdAt).getTime();
			const initialDate = new Date(filteredDate.createdAtStartDate).getTime();
			const endDate = new Date(filteredDate.createdAtEndDate).getTime();
			return (
				currCreatedAtDate >= initialDate &&
				currCreatedAtDate - Numbers.TIME_ONE_DAY_IN_MILLI_SECS <= endDate
			);
		});
	}

	return surveyFilteredData;
};

export const filterEndUserSurveyData = (
	surveyData: IEndUserQuestionnarieListingDataItem[],
	surveyReqData: EndUserListingRequest,
	filteredDate?: IDateFilter
) => {
	let surveyFilteredData = surveyData;
	if (surveyReqData?.questionnaireName) {
		const checkValue = surveyReqData?.questionnaireName
			?.trimEnd()
			.toLowerCase();
		const checkValueId = surveyReqData?.questionnaireName
			?.trimEnd()
			.toLowerCase();
		surveyFilteredData = surveyFilteredData.filter((item) => {
			return (
				item?.questionnaireName?.toLowerCase().includes(checkValue) ||
				item?.questionnaireCode?.toLowerCase().includes(checkValueId)
			);
		});
	}

	if (surveyReqData?.status) {
		surveyFilteredData = surveyFilteredData.filter((item) => {
			return item?.questionnaireStatus === surveyReqData?.status;
		});
	}

	if (surveyReqData?.response) {
		surveyFilteredData = surveyFilteredData.filter((item) => {
			return item?.answerStatus === surveyReqData?.response;
		});
	}

	// check if date is applied add that filter also
	if (
		filteredDate &&
		filteredDate.startDate !== '' &&
		filteredDate.endDate !== ''
	) {
		// apply start date filter
		surveyFilteredData = surveyFilteredData.filter((item) => {
			const currStartDate = new Date(item?.startDate).getTime();
			const initialDate = new Date(filteredDate.startDate).getTime();
			const endDate = new Date(filteredDate.endDate).getTime();
			return (
				currStartDate >= initialDate &&
				currStartDate - Numbers.TIME_ONE_DAY_IN_MILLI_SECS <= endDate
			);
		});
	}

	if (
		filteredDate &&
		filteredDate.createdAtStartDate !== '' &&
		filteredDate.createdAtEndDate !== ''
	) {
		// apply due date filter
		surveyFilteredData = surveyFilteredData.filter((item) => {
			const currCreatedAtDate = new Date(item?.dueDate).getTime();
			const initialDate = new Date(filteredDate.createdAtStartDate).getTime();
			const endDate = new Date(filteredDate.createdAtEndDate).getTime();
			return (
				currCreatedAtDate >= initialDate &&
				currCreatedAtDate - Numbers.TIME_ONE_DAY_IN_MILLI_SECS <= endDate
			);
		});
	}

	return surveyFilteredData;
};

export const getQuestionLabel = (quesType: string) => {
	if (quesType === 'choice') {
		return commonLabels.choice;
	} else if (quesType === 'yesOrNo') {
		return commonLabels.yesOrNo;
	} else if (quesType === 'select') {
		return commonLabels.select;
	} else if (quesType === 'dropdown') {
		return commonLabels.dropdown;
	} else {
		return commonLabels.open;
	}
};

export const getGAMeasurementId = () => {
	const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
	switch (environment) {
		case 'dev':
			return 'G-LJQ8TNSXL2';
		case 'qa':
			return 'G-NLBSWZJSM1';
		case 'production':
			return 'G-NT0KTP5GNJ';
		default:
			return 'G-LJQ8TNSXL2';
	}
};

/**
 * @method
 * @summary Sorts the question-answer array as per the Question-order i.e #1 will appear first then #2, #3 and so on
 * @param questionData as ISurveyFormsQuestion[]
 * @returns ISurveyFormsQuestion
 */
export const sortedQuestionList = (questionData: ISurveyFormsQuestion[]) => {
	return questionData.sort(
		(a: ISurveyFormsQuestion, b: ISurveyFormsQuestion) =>
			(a.order ? a.order : 0) - (b.order ? b.order : 0)
	);
};

/**
 * @method
 * @summary Replaces the special characters to their corresponding ASCII code and returns the entire phrase
 * @param optionString as string
 * @returns string
 */
export const replaceSpecialCharacter = (optionString: string): string => {
	return optionString.replace(specialCharacterForAnswers, function (match) {
		return match.charCodeAt(0).toString();
	});
};
