import {
	DragDropContext,
	Droppable,
	OnDragEndResponder,
} from 'react-beautiful-dnd';
import React from 'react';
import { styled } from '@mui/material';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import QuestionListItem from './QuestionListItem';

export type QuestionsListProps = {
	items: ISurveyFormsQuestion[];
	onDragEnd: OnDragEndResponder;
	deleteQue: (index: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion, index: number) => void;
};

const DroppableContainer = styled('div')({
	marginTop: '-6px',
});

// eslint-disable-next-line react/display-name
const QuestionsList = React.memo(
	({
		items,
		onDragEnd,
		deleteQue,
		onQueClone,
		onQueSave,
	}: QuestionsListProps) => {
		const handlerDeleteQuestion = (index: string) => {
			deleteQue(index);
		};

		const handlerSaveQuestion = (item: ISurveyFormsQuestion, index: number) => {
			onQueSave(item, index);
		};
		const handleQueClone = (data: ISurveyFormsQuestion) => {
			onQueClone(data);
		};
		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable-list">
					{(provided) => (
						<DroppableContainer
							ref={provided.innerRef}
							{...provided.droppableProps}
							data-testid="droppable-list"
						>
							{items.map((item, index) => (
								<QuestionListItem
									onQueSave={handlerSaveQuestion}
									onQueClone={handleQueClone}
									deleteQue={handlerDeleteQuestion}
									item={item}
									index={index}
									key={item.questionId}
								/>
							))}
							{provided.placeholder}
						</DroppableContainer>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
);

export default QuestionsList;
