import React, { useEffect, useRef, useState } from 'react';
import { select, arc, PieArcDatum, pie, scaleOrdinal } from 'd3';
import { Box, IconButton } from '@mui/material';
import { Numbers } from '../../utils/Enum';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-01.svg';
import { DonutChartProps, DonutDataItem } from '../../types/ResultsType';
import StyledTooltip from '../StyledTooltip';
import { useAppSelector } from '../../app/Hooks';
import { keyMetricsAPIStatus } from '../../redux/QuestionnaireResultsSlice';
import { commonLabels } from '../../utils/CommonLabels';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import { ICustomInfoChartProps } from '../../types/CommonTypes';
import CustomInfoChart from './CustomInfoChart';

const DonutChart: React.FC<DonutChartProps> = ({
	data,
	width,
	height,
	centerText,
	heading,
	id,
	summaryData,
	summaryPosition,
	refresh,
	downloadHandler,
	reportSubTypeKey,
	showAdditionalInfo = false,
}) => {
	const [hoveredLabel, setHoveredLabel] = useState<string | null>();
	const keyMetricesAPIStatus = useAppSelector(keyMetricsAPIStatus);
	const svgRef = useRef(null);
	const [showError, setShowError] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [showPieChart, setShowPieChart] = useState(true);
	const [showNoData, setShowNoData] = useState(false);
	const [noDataBodyMsg, setShowNoDataBodyMsg] = useState('');

	const triggerShowNoDataBodyMsg = () => {
		if (heading === commonLabels.notCompliant) {
			setShowNoDataBodyMsg(commonLabels.noDataDefaulterMessage);
		} else if (heading === commonLabels.summitGroupStatus) {
			setShowNoDataBodyMsg(commonLabels.noSummitGroupMessage);
		} else {
			/* empty */
		}
	};

	useEffect(() => {
		// Show loader and additional informtaion only when required
		if (showAdditionalInfo) {
			setShowLoader(true);
			if (keyMetricesAPIStatus === 'success') {
				const noData = data.every((val) => val.value === Numbers.zero);
				if (noData) {
					setShowNoData(true);
					setShowLoader(false);
					setShowPieChart(false);
					setShowError(false);
					triggerShowNoDataBodyMsg();
				} else {
					setShowError(false);
					setShowLoader(false);
					setShowPieChart(true);
					setShowNoData(false);
				}
			} else if (keyMetricesAPIStatus === 'failed') {
				setShowError(true);
				setShowPieChart(false);
				setShowLoader(false);
			} else {
				setShowLoader(true);
				setShowPieChart(false);
				setShowError(false);
				setShowNoData(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyMetricesAPIStatus, data, showLoader, showError, showPieChart]);

	const handleDownload = () => {
		downloadHandler(
			questionnaireAPIKeys.reportTypeKeyMetrics,
			reportSubTypeKey
		);
	};
	useEffect(() => {
		if (data.length === Numbers.zero) return;
		const svg = select(svgRef.current);
		svg.selectAll('*').remove();

		const donutThicknessFactor = Numbers.zeroPointEightEight;
		const radius = Math.min(width, height) / Numbers.two;

		const donutArc = arc<PieArcDatum<DonutDataItem>>()
			.innerRadius(radius * donutThicknessFactor)
			.outerRadius(radius);

		const svgPie = pie<DonutDataItem>()
			.value((d) => d.value)
			.padAngle(Numbers.zeroPointZeroThree);
		const arcs = svgPie(data);
		const color = scaleOrdinal()
			.domain(data.map((d) => d.label))
			.range(data.map((d) => d.color));

		svg
			.selectAll('path')
			.data(arcs)
			.enter()
			.append('path')
			.attr('d', donutArc)
			.attr('fill', (d) => color(d.data.label) as string)
			.attr('stroke', 'white')
			.attr('stroke-width', '2')
			.attr(
				'transform',
				`translate(${width / Numbers.two}, ${height / Numbers.two})`
			)
			.on('mouseover', (_event, d) => {
				setHoveredLabel(d.data.label);
			})
			.on('mouseout', () => {
				setHoveredLabel(null);
			});

		// Split centerText into text and percentage
		const [text, percentage] = centerText.split('|');
		const maxLength = Numbers.ten;
		let percentageYPosition;

		if (text.length > maxLength) {
			const firstPart = text.slice(Numbers.zero, maxLength);
			const secondPart = text.slice(maxLength);

			svg
				.append('text')
				.text(firstPart)
				.attr('x', width / Numbers.two)
				.attr('y', height / Numbers.two - Numbers.twenty)
				.attr('dy', '0.35em')
				.style('text-anchor', 'middle')
				.style('font-family', '"JohnsonDisplay-Bold"')
				.style('font-size', '14px')
				.style('font-weight', Numbers.fiveHundred)
				.style('line-height', '16px');

			svg
				.append('text')
				.text(secondPart)
				.attr('x', width / Numbers.two)
				.attr('y', height / Numbers.two - Numbers.five)
				.attr('dy', '0.35em')
				.style('text-anchor', 'middle')
				.style('font-family', '"JohnsonDisplay-Bold"')
				.style('font-size', '14px')
				.style('font-weight', Numbers.fiveHundred)
				.style('line-height', '16px');

			percentageYPosition = height / Numbers.two + Numbers.fifteen;
		} else {
			svg
				.append('text')
				.text(text)
				.attr('x', width / Numbers.two)
				.attr('y', height / Numbers.two - Numbers.ten)
				.attr('dy', '0.35em')
				.style('text-anchor', 'middle')
				.style('font-family', '"JohnsonDisplay-Bold"')
				.style('font-size', '16px')
				.style('font-weight', Numbers.sevenHundred)
				.style('line-height', '32px');

			percentageYPosition = height / Numbers.two + Numbers.twenty;
		}

		// Append percentage
		svg
			.append('text')
			.text(percentage)
			.attr('x', width / Numbers.two)
			.attr('y', percentageYPosition)
			.attr('dy', '0.35em')
			.style('text-anchor', 'middle')
			.style('font-family', '"JohnsonDisplay-Bold"')
			.style('font-size', '25px')
			.style('font-weight', Numbers.fiveHundred)
			.style('line-height', '32px');
	}, [data, width, height, centerText, id, showPieChart]);

	return (
		<Box
			display="flex"
			flexDirection={summaryPosition === 'beside' ? 'row' : 'column'}
			alignItems="center"
			gap={summaryPosition === 'beside' ? '20px' : '0px'}
			height="auto"
			width={summaryPosition !== 'beside' ? '100%' : '50%'}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: summaryPosition === 'beside' ? '41%' : '100%',
				}}
			>
				<Box
					display="flex"
					justifyContent="space-between"
					sx={{ paddingTop: '1.5vh' }}
				>
					<Box
						sx={{
							fontSize: '24px',
							fontWeight: Numbers.fiveHundred,
							fontFamily: '"JohnsonDisplay-Bold"',
							lineHeight: '30px',
							whiteSpace: 'nowrap',
						}}
					>
						{heading}
					</Box>
					{summaryPosition === 'below' && (
						<Box
							sx={{
								cursor: 'pointer',
							}}
						>
							<StyledTooltip title={'Export results to Excel'} placement="top">
								<IconButton onClick={handleDownload}>
									<DownloadIcon />
								</IconButton>
							</StyledTooltip>
						</Box>
					)}
				</Box>
				{showPieChart && (
					<Box
						sx={{
							marginTop: '20px',
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<svg ref={svgRef} id={id} width={width} height={height}></svg>
						{hoveredLabel && (
							<div className="donutchart-tooltip">{hoveredLabel}</div>
						)}
					</Box>
				)}
			</Box>
			{/*  Description for the chart summary */}
			{/* This section can be positioned @right or @bottom */}
			{showPieChart && (
				<Box
					sx={{
						display: 'flex',
						justifyContent:
							summaryPosition === 'beside' ? 'center' : 'space-between',
						flexDirection: summaryPosition === 'beside' ? 'column' : 'row',
						gap: '25px',
						alignItems: 'center',
						padding: '0 20px',
						marginTop: summaryPosition === 'below' ? '25px' : '0px',
						width: '100%',
					}}
				>
					{summaryData?.map((element) => (
						<Box
							key={element.label}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							{summaryPosition === 'below' && (
								<Box
									sx={{
										fontFamily: '"JohnsonDisplay-Bold"',
										fontWeight: Numbers.sevenHundred,
										fontSize: '14px',
										lineHeight: '24px',
										alignItems: 'center',
									}}
								>
									{element.value}
									{centerText.includes('%') && '%'}
								</Box>
							)}
							<ChartIndicator color={element.color} label={element.label} />
						</Box>
					))}
				</Box>
			)}
			<ShowLoaderFragment
				showFragment={showError}
				paddingTop={'7vh'}
				type={'error'}
				handleRefresh={refresh}
				titleText={commonLabels.errorText}
				bodyText={commonLabels.graphRenderErrorText}
			/>
			<ShowLoaderFragment
				showFragment={showNoData}
				paddingTop={'7vh'}
				type={'info'}
				titleText={commonLabels.noDataFound}
				bodyText={noDataBodyMsg}
			/>
			<ShowLoaderFragment
				showFragment={showLoader}
				paddingTop={'3vh'}
				type={'loading'}
				titleText={commonLabels.dataLoadingTxt}
				bodyText={commonLabels.dataLoadingMsgTxt}
			/>
		</Box>
	);
};

export const ShowLoaderFragment: React.FC<{
	showFragment: boolean;
	paddingTop: string;
	type: ICustomInfoChartProps['type'];
	handleRefresh?: ICustomInfoChartProps['handleRefresh'];
	titleText: string;
	bodyText: string;
}> = ({
	showFragment,
	paddingTop,
	type,
	handleRefresh,
	titleText,
	bodyText,
}) => {
	return (
		<>
			{showFragment && (
				<Box sx={{ paddingTop: paddingTop }}>
					<CustomInfoChart
						type={type}
						handleRefresh={handleRefresh}
						titleText={titleText}
						bodyText={bodyText}
					/>
				</Box>
			)}
		</>
	);
};

const ChartIndicator: React.FC<{ color: string; label: string }> = ({
	color,
	label,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				maxWidth: '100%',
				gap: '10px',
			}}
		>
			<Box
				sx={{
					minWidth: '10px',
					minHeight: '10px',
					borderRadius: '50%',
					background: color,
				}}
			></Box>
			<Box
				sx={{
					fontSize: '14px',
					lineHeight: '16px',
					fontWeight: Numbers.fourHundred,
					fontFamily: '"JohnsonText-Regular"',
					wordWrap: 'break-word',
					whiteSpace: 'pre-line',
					textAlign: 'left',
					minWidth: '60px',
				}}
			>
				{label}
			</Box>
		</Box>
	);
};

export default DonutChart;
